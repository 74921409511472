import React, { useRef, useContext, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CFormCheck,
  CInputGroupText,
} from "@coreui/react";
import "../../../assets/css/login.css";
import { toast } from "react-toastify";
import CIcon from "@coreui/icons-react";
import { useNavigate } from "react-router-dom";
import { cilLockLocked, cilUser } from "@coreui/icons";
import AuthContext from "../../shared/AuthContext";
import NewtonNoBG from "../../../assets/brand/NewtonInsightsBranding/NewtonNoBG.png";
import { useMediaQuery } from "react-responsive";

import { getDevices } from "../../shared/devices";

const Login = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      "useContext(AuthContext) must be used within a AuthContextProvider"
    );
  }

  const { login } = authContext;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const loginButtonRef = useRef<HTMLButtonElement>(null);
  const isTablet = useMediaQuery({ maxWidth: 714, maxHeight: 1200 });
  const isTabletLandscape = useMediaQuery({
    maxWidth: 1200,
    maxHeight: 714,
    orientation: "landscape",
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error(`Please enter your ${!email ? "email" : "password"}`);
      return;
    }

    try {
      const loginData = new FormData();
      loginData.append("username", email);
      loginData.append("password", password);

      await login(loginData);
      await getDevices();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const handleForgotPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/ForgotPassword");
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center set-background-image">
      <CContainer>
        <div className="d-flex justify-content-center">
          <CCol md={isTablet ? 8 : isTabletLandscape ? 8 : 7}>
            <div className="glass-container p-4">
              <CForm onSubmit={handleLogin}>
                <div className="image-container">
                  <img src={NewtonNoBG} alt="Insights" className="image" />
                </div>
                <p className="main-header">Log in to Newton Insights</p>
                <div className="form-content">
                  <CInputGroup className="mb-3" size={isTablet ? "sm" : "lg"}>
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      id="email"
                      placeholder="Email"
                      autoComplete="email"
                      value={email}
                      name="email"
                      className="input"
                      onChange={handleEmailChange}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4" size={isTablet ? "sm" : "lg"}>
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="current-password"
                      value={password}
                      className="input"
                      onChange={handlePasswordChange}
                    />
                    <CInputGroupText
                      onClick={togglePasswordVisibility}
                      className="password-toggle"
                    >
                      {showPassword ? "🔓" : "🔒"}
                    </CInputGroupText>
                  </CInputGroup>

                  <div className="flexContainer">
                    <div>
                      <CFormCheck id="rememberMe" label="" />
                    </div>
                    <em>Remember me</em>
                  </div>

                  <div className="button-container">
                    <CButton
                      ref={loginButtonRef}
                      className="custom-button"
                      type="submit"
                    >
                      Login
                    </CButton>
                  </div>

                  <div className="button-container d-flex justify-content-between w-100">
                    <button
                      type="button"
                      className="forgot-password"
                      onClick={handleForgotPassword}
                    >
                      <small>Forgot Password</small>
                    </button>
                    <button
                      type="button"
                      className="register-link"
                      onClick={() => navigate("/register")}
                    >
                      Register here
                    </button>
                  </div>
                </div>
              </CForm>
              <h2 className="company-name">Powered by 8th Revolution</h2>
            </div>
          </CCol>
        </div>
      </CContainer>
    </div>
  );
};

export default Login;
