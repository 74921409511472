import React, { useState } from 'react';
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CForm,
  CFormInput,
  CFormSelect,
  CTooltip,
  CSpinner
} from '@coreui/react';
import { FaQuestionCircle } from 'react-icons/fa';
import jwtInterceoptor from '../../../../../views/shared/jwtInterceptor';
import { toast } from 'react-toastify';
import './styles.css';

interface TeamMember {
  name: string;
  email: string;
  role: string;
}

interface AddTeamMembersProps {
  onComplete: () => void;
  onSkip: () => void;
}

const AddTeamMembers: React.FC<AddTeamMembersProps> = ({ onComplete, onSkip }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState<TeamMember[]>([]);
  const [newMember, setNewMember] = useState<TeamMember>({
    name: '',
    email: '',
    role: ''
  });

  const handleAddMember = async () => {
    setIsLoading(true);
    try {
      const response = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/team-members/`,
        {
          name: newMember.name,
          email: newMember.email,
          role: newMember.role
        }
      );

      if (response.status === 201) {
        setMembers([...members, newMember]);
        setNewMember({ name: '', email: '', role: '' });
        setShowModal(false);
        toast.success('Team member added successfully!');
      }
    } catch (error) {
      onComplete();
      toast.error('Failed to add team member. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-team-members">
      <div className="step-header">
        <h2>Add Team Members</h2>
        <p className="estimated-time">Estimated Time: 2-3 minutes</p>
      </div>

      <div className="team-members-list">
        {members.map((member, index) => (
          <div key={index} className="member-card">
            <div className="member-info">
              <h4>{member.name}</h4>
              <p>{member.email}</p>
              <span className={`role-badge ${member.role}`}>
                {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="actions">
        <CButton
          color="dark"
          onClick={() => setShowModal(true)}
          // className="add-member-btn"
        >
          Add Team Member
        </CButton>
      </div>

      <div className="navigation-buttons">
      <CButton
          color="dark"
          variant="ghost"
          onClick={onSkip}
          className="skip-button"
          style={{ backgroundColor: '#f0f0f0' }}
        >
          Skip for now
        </CButton>
        <CButton
          color="dark"
          onClick={onComplete}
          disabled={members.length === 0}
        >
          Continue
        </CButton>
      </div>

      <CModal
        visible={showModal}
        alignment="center"
        onClose={() => setShowModal(false)}
      >
        <CModalHeader>
          <CModalTitle>Add Team Member</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <div className="mb-3">
              <CFormInput
                label="Name"
                value={newMember.name}
                onChange={(e) => setNewMember({...newMember, name: e.target.value})}
                required
              />
            </div>
            <div className="mb-3">
              <CFormInput
                type="email"
                label="Email"
                value={newMember.email}
                onChange={(e) => setNewMember({...newMember, email: e.target.value})}
                required
              />
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center mb-2">
                <label className="form-label mb-0 me-2">Role</label>
                <CTooltip
                  content={
                    newMember.role === 'admin'
                      ? "Admins can manage settings, invite new users, and monitor system configurations."
                      : "Operators focus on day-to-day operations and can view real-time data and reports."
                  }
                  placement="right"
                >
                  <span>
                    <FaQuestionCircle className="text-muted" />
                  </span>
                </CTooltip>
              </div>
              <CFormSelect
                value={newMember.role}
                onChange={(e) => setNewMember({...newMember, role: e.target.value as 'admin' | 'operator'})}
                options={[
                  { label: 'Select a role', value: '' },
                  { label: 'Operator', value: 'operator' },
                  { label: 'Admin', value: 'admin' }
                ]}
              />
            </div>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="dark"
            variant="ghost"
            onClick={() => setShowModal(false)}
            className="skip-button"
            style={{ backgroundColor: '#f0f0f0' }}
          >
            Cancel
          </CButton>
          <CButton
            color="dark"
            onClick={handleAddMember}
            disabled={isLoading}
          >
            {isLoading ? <CSpinner size="sm" /> : 'Add Member'}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default AddTeamMembers;
