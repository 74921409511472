import React, { useState } from 'react';
import {
  CContainer,
  CAlert
} from '@coreui/react';
import ExtractionForm from '../../../components/Digital_Twin/ExtractionForm';
import { apiClient } from '../../../hooks/useDigitalTwin';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/simulation.css';

interface Solute {
    id: number;
    concentration: number;
}

interface SimulationParameters {
    plant_material: {
        strain: string;
        mass: number;
        particle_size: number;
        bed_porosity: number;
        effective_diffusivity: number;
        cannabinoid_percentage: number;
    };
    solvent_id: number;
    solvent_volume: number;
    temperature: number;
    pressure: number;
    duration: number;
    time_step: number;
    solutes: Solute[];
    email?: string;
}

const DigitalTwinPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleRunSimulation = async (parameters: SimulationParameters) => {
    setIsLoading(true);
    setError(null);

    console.log('Starting simulation with parameters:', parameters);

    try {
      const response = await apiClient.runExtraction(parameters);
      console.log('Simulation successful, response:', response);
      navigate(`/sim-results/${response.simulation_run_id}`);
    } catch (error: any) {
      console.error('Error running simulation:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
      }
      setError(error.response?.data?.error || error.message || 'An unknown error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <CContainer className="digital-twin-container">
        <div className="content-wrapper">
          {error && (
            <CAlert color="danger" className="error-alert">
              {error}
            </CAlert>
          )}

          <ExtractionForm onSimulationRun={handleRunSimulation} isLoading={isLoading} />
        </div>
      </CContainer>
    </div>
  );
};

export default DigitalTwinPage;
