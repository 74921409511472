import React, {ReactNode} from 'react'
import '../../assets/css/common.css'
import AppFooter from '../AppFooter'
import AppHeader from '../AppHeader'

interface DefaultLayoutProps {
	children: ReactNode;
	showHeader?: boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, showHeader = true }) => {


	return (
    <div className="ScanHardware-main">
      <AppHeader />
      <div className="body">
        {children}
      </div>
      {showHeader && <AppFooter />}
    </div>
  );
}

export default DefaultLayout
