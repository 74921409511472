import React, {useContext, useState, useEffect} from 'react'
import {
	CButton,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CRow,
  CTooltip,
} from '@coreui/react'
import '../../../assets/css/register.css'
import {toast} from 'react-toastify';
import CIcon from '@coreui/icons-react'
import {cilLockLocked, cilUser, cilCog} from '@coreui/icons'
import AuthContext from '../../shared/AuthContext'
import NewtonNoBG from '../../../assets/brand/NewtonInsightsBranding/NewtonNoBG.png'
import PasswordStrengthBar from 'react-password-strength-bar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Register = () => {

	const authContext = useContext(AuthContext);
	if (!authContext) {
		throw new Error('useContext(AuthContext) must be used within a AuthContextProvider');
	}
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const { register } = authContext;
	const navigate = useNavigate();

	// State variables to manage form inputs
	const [lastName, setLastName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confpassword, setConfPassword] = useState('');
	const [activationKey, setActivationKey] = useState('');
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const [isLoading, setIsLoading] = useState(false);

	// Add new state variables for validation
	const [isFormValid, setIsFormValid] = useState(false);
	const [errors, setErrors] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confpassword: '',
		activationKey: ''
	});

	// Validation functions
	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const validatePassword = (password: string) => {
		const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
		return passwordRegex.test(password);
	};

	// Check form validity
	useEffect(() => {
		const isValid =
			firstName.trim() !== '' &&
			lastName.trim() !== '' &&
			validateEmail(email) &&
			validatePassword(password) &&
			password === confpassword &&
			activationKey.trim() !== '' &&
			Object.values(errors).every(error => error === '');

		setIsFormValid(isValid);
	}, [firstName, lastName, email, password, confpassword, activationKey, errors]);

	// Update form input handlers with validation
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setEmail(value);
		if (!validateEmail(value)) {
			setErrors(prev => ({ ...prev, email: 'Please enter a valid email address' }));
		} else {
			setErrors(prev => ({ ...prev, email: '' }));
		}
	};

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setPassword(value);
		if (!validatePassword(value)) {
			setErrors(prev => ({ ...prev, password: 'Password must be at least 8 characters long and contain at least one letter, one number, and one special character' }));
		} else {
			setErrors(prev => ({ ...prev, password: '' }));
		}
	};

	// Function to handle the form submission
	const handleRegister = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/user/register/`,
				{
					email: email,
					password: password,
					first_name: firstName,
					last_name: lastName,
					activationKey: activationKey,
				}
			);

			if (response.status === 201) {
				toast.success("Registration successful!");
				// Add a small delay before redirecting to ensure the toast is visible
				setTimeout(() => {
					navigate('/onboarding');  // Redirect to onboarding page
				}, 1500);
			}
		} catch (error: any) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	// Return the JSX to render the registration form
	return (
    <div className="bg-light min-vh-100 d-flex align-items-center justify-content-center set-background-image">
      <CContainer className="py-3">
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={5}>
            <div className="form-container">
              <div className="text-center mb-3">
                <img
                  src={NewtonNoBG}
                  alt="Newton Insights"
                  className="img-fluid mb-3"
                  style={{ maxWidth: "220px" }}
                />
                <p className="fw-bold mb-1 px-4 form-header">
                  Start your Journey with Newton Insights
                </p>
              </div>

              <CForm onSubmit={handleRegister}>
                <p className="text-center mb-3 form-subheader">
                  Create your account
                </p>

                <div className="name-inputs">
                  <CCol className="px-1">
                    <CInputGroup className="input-group2">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        autoComplete="given-name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        invalid={errors.firstName !== ""}
                        feedback={errors.firstName}
                      />
                    </CInputGroup>
                  </CCol>
                  <CCol className="px-1">
                    <CInputGroup className="input-group2">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Last Name"
                        autoComplete="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        invalid={errors.lastName !== ""}
                        feedback={errors.lastName}
                      />
                    </CInputGroup>
                  </CCol>
                </div>

                <CInputGroup className="mb-2" style={{ padding: "0 3rem" }}>
                  <CInputGroupText>@</CInputGroupText>
                  <CTooltip
                    content="Enter the email address you want to use for your company account"
                    placement="right"
                  >
                    <CFormInput
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      autoComplete="email"
                      value={email}
                      onChange={handleEmailChange}
                      invalid={errors.email !== ""}
                      feedback={errors.email}
                    />
                  </CTooltip>
                </CInputGroup>

                <CInputGroup className="mb-2" style={{ padding: "0 3rem" }}>
                  <CInputGroupText>
                    <CIcon icon={cilLockLocked} />
                  </CInputGroupText>
                  <CTooltip
                    content="Use at least 8 characters, including a number and a symbol, for added security"
                    placement="right"
                  >
                    <CFormInput
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      value={password}
                      onChange={handlePasswordChange}
                      invalid={errors.password !== ""}
                      feedback={errors.password}
                    />
                  </CTooltip>
                </CInputGroup>

                <PasswordStrengthBar
                  password={password}
                  style={{ width: "75%", margin: "0 auto" }}
                />

                <CInputGroup className="mb-2" style={{ padding: "0 3rem" }}>
                  <CInputGroupText>
                    <CIcon icon={cilLockLocked} />
                  </CInputGroupText>
                  <CTooltip
                    content="Re-enter your password to confirm"
                    placement="right"
                  >
                    <CFormInput
                      type="password"
                      placeholder="Confirm Password"
                      value={confpassword}
                      onChange={(e) => setConfPassword(e.target.value)}
                    />
                  </CTooltip>
                </CInputGroup>

                <CInputGroup className="mb-4" style={{ padding: "0 3rem" }}>
                  <CInputGroupText>
                    <CIcon icon={cilCog} />
                  </CInputGroupText>
                  <CTooltip
                    content="Find this key on your hardware or in the email we sent you"
                    placement="right"
                  >
                    <CFormInput
                      placeholder="Activation Key"
                      value={activationKey}
                      onChange={(e) => setActivationKey(e.target.value)}
                    />
                  </CTooltip>
                </CInputGroup>

                <div className="text-center">
                  <CButton
                    color="light"
                    type="submit"
                    disabled={!isFormValid}
                    className="submit-button"
                  >
                    Create Account
                  </CButton>
                </div>

                <div className="text-center mt-2">
                  <a
                    href="/"
                    className="text-decoration-none"
                    style={{ fontSize: "14px", color: "#7676c2" }}
                  >
                    Already have an account? Login
                  </a>
                </div>
                <p className="my-3 text-center">
                  Powered by 8th Revolution
                </p>
              </CForm>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default Register;
