import React from 'react'
import {
	CDropdown,
	CDropdownHeader,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from '@coreui/react'
import {
  cilControl,
  cilViewQuilt,
	cilBell,
	cilSettings,
	cilUser,
	cilSearch,
    cilTablet
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react'
import { cookieUtils } from '../../utils/cookieUtils';

interface AppHeaderDropdownProps {
	logout: () => void;
}
const AppHeaderDropdown: React.FC<AppHeaderDropdownProps> = ({ logout }) => {
	const [showOnboarding, setShowOnboarding] = useState(true);
	const [pendingSteps, setPendingSteps] = useState<string[]>([]);
	let navigate = useNavigate();

	useEffect(() => {
		const checkOnboardingStatus = () => {
			const status = cookieUtils.get('onboardingStatus');
			if (!status) {
				// If no cookies data, show everything
				setPendingSteps([]);
				setShowOnboarding(true);
				return;
			}

			const parsedStatus = JSON.parse(status);
			const incomplete = parsedStatus.filter((step: { completed: boolean; name: string }) => !step.completed)
				.map((step: { name: string }) => step.name);

			setPendingSteps(incomplete);
			setShowOnboarding(incomplete.length > 0);
		};

		checkOnboardingStatus();
		// Add event listener for storage changes
		window.addEventListener('storage', checkOnboardingStatus);
		return () => window.removeEventListener('storage', checkOnboardingStatus);
	}, []);

	const handleLogout = () => {
		logout()
	}

	return (
		<CDropdown variant="nav-item">
			<CDropdownToggle className="py-0" caret={false}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div style={{display: 'flex', flexDirection: 'column'}}>
						<p style={{marginBottom: '0px', paddingRight: '10px'}}>
							<b>{cookieUtils.get('UserName')}</b>
						</p>
					</div>
					{/* <CAvatar src={avatar8} size="md" style={{width:"45px",height:"30px"}}/> */}
					<svg xmlns="http://www.w3.org/2000/svg" width="0.9em" height="1em" viewBox="0 0 630 700">
						<path fill="currentColor" d="M622 106L311 417L0 106l65-65l246 245L556 41z"/>
					</svg>
				</div>
			</CDropdownToggle>
			<CDropdownMenu className="pt-0">
				<CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
				{showOnboarding && (
					<CDropdownItem
						onClick={() => {
							navigate('/Onboarding')
						}}
						className="poniter"
						title={`Pending steps: ${pendingSteps.join(', ')}`}
					>
						<CIcon icon={cilUser} className="me-2"/>
						Onboarding
					</CDropdownItem>
				)}
				<CDropdownItem
					onClick={() => {
						navigate('/SystemControlCenter')
					}}
					className="poniter"
				>
					<CIcon icon={cilControl} className="me-2"/>
					System Control Center
				</CDropdownItem>
				<CDropdownItem
					onClick={() => {
						navigate('/ExtractoPredictionDashboard')
					}}
					className="poniter"
				>
					<CIcon icon={cilViewQuilt} className="me-2"/>
					Extracto Dashboard
				</CDropdownItem>
				<CDropdownItem
					onClick={() => {
						navigate('/managerdashboard')
					}}
					className="poniter"
				>
					<CIcon icon={cilViewQuilt} className="me-2"/>
					Manager Dashboard
				</CDropdownItem>
        <CDropdownItem
					onClick={() => {
						navigate('/RunData')
					}}
					className="poniter"
				>
					<CIcon icon={cilTablet} className="me-2"/>
					Run Data
				</CDropdownItem>
				<CDropdownItem
					onClick={() => {
						navigate('/Settings')
					}}
					className="poniter"
				>
					<CIcon icon={cilSettings} className="me-2"/>
					Settings
				</CDropdownItem>
				<CDropdownItem className="onlyMobile poniter">
					<CIcon icon={cilBell} className="me-2"/>
					Notifications
				</CDropdownItem>
				<CDropdownItem className="onlyMobile pointer">
					<CIcon icon={cilSearch} className="me-2"/>
					Search
				</CDropdownItem>
				<CDropdownItem onClick={handleLogout} className="poniter">
					<CIcon icon={cilUser} className="me-2"/>
					Logout
				</CDropdownItem>
			</CDropdownMenu>
		</CDropdown>
	)
}

export default AppHeaderDropdown
