import React from 'react';
import { CCard, CCardBody } from '@coreui/react';

interface NewtonScoreDisplayProps {
  score: number;
}

const NewtonScoreDisplay: React.FC<NewtonScoreDisplayProps> = ({ score }) => {
  // Format the score - if it's NaN, display "NaN", otherwise show the number
  const formattedScore = isNaN(score) ? "NaN" : score;

  return (
    <CCard className="metric-card glass-effect">
      <CCardBody>
        <div className="d-flex flex-column align-items-center">
          <h4 className="card-title">Newton Score</h4>
          <div className="metric-value display-4 text-center">
            {formattedScore}
          </div>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default NewtonScoreDisplay;
