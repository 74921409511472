import React from 'react';

interface DeleteConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  itemName: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  show,
  onHide,
  onConfirm,
  itemName
}) => {
  if (!show) return null;

  return (
    <div className="inventory-overlay" onClick={onHide}>
      <div className="inventory-modal delete-modal" onClick={e => e.stopPropagation()}>
        <div className="inventory-modal-header">
          <h2 className="inventory-modal-title">Confirm Deletion</h2>
        </div>
        <div className="inventory-modal-body">
          <div className="delete-confirmation-message">
            <div className="delete-icon">⚠️</div>
            <p>Are you sure you want to delete <strong>{itemName}</strong>?</p>
            <p className="delete-warning">This action cannot be undone.</p>
          </div>
        </div>
        <div className="inventory-modal-footer">
          <button
            className="inventory-button inventory-button-secondary"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            className="inventory-button inventory-button-danger"
            onClick={() => {
              onConfirm();
              onHide();
            }}
          >
            Delete Item
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;