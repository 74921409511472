import React, { useState, useEffect } from 'react';
import {
  CContainer,
  CRow,
  CCol,
  CFormSelect,
  CFormInput,
  CButton,
  CTooltip
} from '@coreui/react';
import { apiClient } from '../../services/api';
import "../../assets/css/simulation.css";
import ResultsEmailModal from './ResultsEmailModal';
import { cookieUtils } from '../../utils/cookieUtils';

// Define types locally until the module is properly set up
interface Compound {
  id: number;
  name: string;
  compound_type: string;
  chemical_class: string;
  email?: string;
}

interface Solute {
  id: number;
  concentration: number;
}

interface SimulationParameters {
  plant_material: {
    strain: string;
    mass: number;
    particle_size: number;
    bed_porosity: number;
    effective_diffusivity: number;
    cannabinoid_percentage: number;
    terpene_percentage: number;
  };
  solvent_id: number;
  solvent_volume: number;
  temperature: number;
  pressure: number;
  duration: number;
  time_step: number;
  solutes: Solute[];
}

interface ExtractionFormProps {
  onSimulationRun: (parameters: SimulationParameters) => Promise<void>;
  isLoading?: boolean;
}

const ExtractionForm: React.FC<ExtractionFormProps> = ({ onSimulationRun, isLoading = false }) => {
  // Basic parameters
  const [plantMass, setPlantMass] = useState(5000);
  const [solventVolume, setSolventVolume] = useState(50.0);
  const [temperature, setTemperature] = useState(-40.0);
  const [pressure, setPressure] = useState(3.7);
  const [duration, setDuration] = useState(10);
  const [timeStep, setTimeStep] = useState(0.5);
  const [particleSize, setParticleSize] = useState(12);
  const [bedPorosity, setBedPorosity] = useState(0.4);
  const [diffusivity, setDiffusivity] = useState(0.000000001);
  
  // Compound selection
  const [selectedSolvent, setSelectedSolvent] = useState<number | null>(null);
  const [solutes, setSolutes] = useState<Compound[]>([]);
  const [solvents, setSolvents] = useState<Compound[]>([]);
  const [selectedSolutes, setSelectedSolutes] = useState<{ id: number; concentration: number }[]>([
    { id: 0, concentration: 0 },
  ]);
  // const [showSolutes, setShowSolutes] = useState(true);
  
  // Add new state for the email modal
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [emailSubmitting, setEmailSubmitting] = useState<boolean>(false);
  const [simulationParams, setSimulationParams] = useState<SimulationParameters | null>(null);
  
  // Add new state for email check
  const [shouldShowEmailForm, setShouldShowEmailForm] = useState<boolean>(true);
  const [userEmail, setUserEmail] = useState<string>(cookieUtils.get('simulation_email') || '');

  useEffect(() => {
    console.log('Fetching compounds...');
    apiClient.getCompounds().then((response) => {
      console.log('API Response:', response);
      console.log('Response compounds:', response.compounds);
      
      const filteredSolutes = response.compounds.filter((c: Compound) => ['solute', 'both'].includes(c.compound_type));
      const filteredSolvents = response.compounds.filter((c: Compound) => ['solvent', 'both'].includes(c.compound_type));
      
      console.log('Filtered solutes:', filteredSolutes);
      console.log('Filtered solvents:', filteredSolvents);
      
      setSolutes(filteredSolutes);
      setSolvents(filteredSolvents);
      
      console.log('State updated - compounds should appear in dropdowns');
    }).catch(error => {
      console.error('Error fetching compounds:', error);
      // Add detailed error logging
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Status:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    });
  }, []);

  useEffect(() => {
    // Check if user has already submitted email
    const checkPreviousSubmission = async () => {
      try {
        const response = await apiClient.checkEmail();
        setShouldShowEmailForm(response.show_form);
      } catch (error) {
        console.error('Error checking previous email submission:', error);
        // Default to showing the form if there's an error
        setShouldShowEmailForm(true);
      }
    };
    
    checkPreviousSubmission();
  }, []);

  const handleAddSolute = () => {
    setSelectedSolutes([...selectedSolutes, { id: 0, concentration: 0 }]);
  };

  const handleRemoveSolute = (index: number) => {
    const newSolutes = [...selectedSolutes];
    newSolutes.splice(index, 1);
    setSelectedSolutes(newSolutes);
  };

  const handleSoluteChange = (index: number, field: 'id' | 'concentration', value: number) => {
    const newSolutes = [...selectedSolutes];
    newSolutes[index][field] = value;
    setSelectedSolutes(newSolutes);
  };

  const handleRunSimulation = async () => {
    // Get the selected solutes and their concentrations
    const cannabinoidSolute = selectedSolutes.find(solute => 
      solutes.find(s => s.id === solute.id)?.chemical_class.toLowerCase().includes('cannabinoid')
    );
    
    const terpeneSolute = selectedSolutes.find(solute => 
      solutes.find(s => s.id === solute.id)?.chemical_class.toLowerCase().includes('terpene')
    );
    
    // Default to first solute's concentration if no specific cannabinoid is found
    const cannabinoidPercentage = cannabinoidSolute 
      ? cannabinoidSolute.concentration 
      : (selectedSolutes.length > 0 ? selectedSolutes[0].concentration : 0);
    
    // Get terpene percentage or default to 0
    const terpenePercentage = terpeneSolute 
      ? terpeneSolute.concentration 
      : 0;
    
    const rawParameters = {
      plant_material: {
        strain: 'Example Strain',
        mass: plantMass,
        particle_size: particleSize,
        bed_porosity: bedPorosity,
        effective_diffusivity: diffusivity,
        cannabinoid_percentage: cannabinoidPercentage,
        terpene_percentage: terpenePercentage,
      },
      solvent_id: selectedSolvent || 0,
      solvent_volume: solventVolume,
      temperature: temperature,
      pressure: pressure,
      duration: duration,
      time_step: timeStep,
      solutes: selectedSolutes.map(solute => ({
        id: solute.id,
        concentration: solute.concentration
      })),
      email: cookieUtils.get('simulation_email') || userEmail
    };

    console.log('Raw simulation parameters:', rawParameters);
    
    // Store parameters and show email modal only if needed
    setSimulationParams(rawParameters as SimulationParameters);
    
    if (shouldShowEmailForm) {
      setShowEmailModal(true);
    } else {
      // If we don't need to show the form, run simulation directly
      try {
        await onSimulationRun(rawParameters as SimulationParameters);
      } catch (error: any) {
        console.error('Error running simulation:', error);
        if (error.response && error.response.data && error.response.data.error) {
          alert(`Simulation error: ${error.response.data.error}`);
        } else {
          alert(`Error running simulation: ${error.message || 'Unknown error'}`);
        }
      }
    }
  };

  const handleEmailSubmit = async (name: string, email: string, company: string) => {
    if (!simulationParams) return;
    
    // Update the email state
    setUserEmail(email);
    setEmailSubmitting(true);
    
    try {
      // First send the email information
      await apiClient.sendEmailInfo({ name, email, company });
      
      // Update the simulation parameters with the email
      const updatedParams = {
        ...simulationParams,
        email: email
      };
      
      // Then run the simulation with updated parameters
      await onSimulationRun(updatedParams);
      
      // Close the modal
      setShowEmailModal(false);
    } catch (error: any) {
      console.error('Error in email submission or simulation:', error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error: ${error.response.data.error}`);
      } else {
        alert(`Error: ${error.message || 'Unknown error'}`);
      }
    } finally {
      setEmailSubmitting(false);
    }
  };

  // Use the existing CoreUI style for consistency
  return (
    <CContainer className="simulation-form-container mt-4">
      <div className="simulation-header">
        <h2>Extraction Simulator: Because Guessing Costs You — Optimize Your Cannabis Process!</h2>
        <p className="lead text-center mb-2">Adjust parameters to boost yield and efficiency.</p>
        <p className="text-center mb-4">Run the simulation to get your score, then take the parameters to maximize your real-life extraction process!</p>
      </div>

      <CRow className="mb-4">
        {/* Material Properties */}
        <CCol xs={12} md={4} className="mb-4">
          <div className="sim-form-card glass-effect">
            <h3>Material Properties</h3>

            <CRow>
              <CCol xs={12} sm={6}>
                <div className="sim-form-group">
                  <label htmlFor="plantMass">Plant Mass (g)</label>
                  <CTooltip content="The total mass of plant material to be processed in grams" placement="bottom">
                    <CFormInput
                      type="number"
                      id="plantMass"
                      value={plantMass}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPlantMass(Number(e.target.value))}
                      min={1}
                      placeholder="Enter plant mass"
                    />
                  </CTooltip>
                </div>
              </CCol>
              <CCol xs={12} sm={6}>
                <div className="sim-form-group">
                  <label htmlFor="particleSize">Particle Size (mm)</label>
                  <CTooltip content="Average size of plant material particles after grinding. Smaller particles increase extraction efficiency but may cause clogging" placement="bottom">
                    <CFormInput
                      type="number"
                      id="particleSize"
                      value={particleSize}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setParticleSize(Number(e.target.value))}
                      min={0.1}
                      step={0.1}
                    />
                  </CTooltip>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={12} sm={6}>
                <div className="sim-form-group">
                  <label htmlFor="bedPorosity">Bed Porosity</label>
                  <CTooltip content="The fraction of void space in the packed bed (0-1). Higher values allow better solvent flow but may reduce contact time" placement="bottom">
                    <CFormInput
                      type="number"
                      id="bedPorosity"
                      value={bedPorosity}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setBedPorosity(Number(e.target.value))}
                      min={0}
                      max={1}
                      step={0.01}
                    />
                  </CTooltip>
                </div>
              </CCol>
              <CCol xs={12} sm={6}>
                <div className="sim-form-group">
                  <label htmlFor="diffusivity">Diffusivity (m²/s)</label>
                  <CTooltip content="Rate at which compounds diffuse through the plant material. Higher values indicate faster extraction" placement="bottom">
                    <CFormInput
                      type="text"
                      id="diffusivity"
                      value={diffusivity.toExponential(9)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setDiffusivity(Number(e.target.value))}
                    />
                  </CTooltip>
                </div>
              </CCol>
            </CRow>
          </div>
        </CCol>

        {/* Operating Conditions */}
        <CCol xs={12} md={4} className="mb-4">
          <div className="sim-form-card glass-effect">
            <h3>Operating Conditions</h3>

            <CRow>
              <CCol xs={12} sm={6}>
                <div className="sim-form-group">
                  <label htmlFor="temperature">Temperature (°C)</label>
                  <CTooltip content="Operating temperature affects solubility and extraction rate. Higher temperatures increase efficiency but may degrade some compounds" placement="bottom">
                    <CFormInput
                      type="number"
                      id="temperature"
                      value={temperature}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setTemperature(Number(e.target.value))}
                    />
                  </CTooltip>
                </div>
              </CCol>
              <CCol xs={12} sm={6}>
                <div className="sim-form-group">
                  <label htmlFor="pressure">Pressure (bar)</label>
                  <CTooltip content="Operating pressure affects solvent properties. Higher pressures can increase extraction efficiency for supercritical fluids" placement="bottom">
                    <CFormInput
                      type="number"
                      id="pressure"
                      value={pressure}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPressure(Number(e.target.value))}
                      min={0.1}
                      step={0.1}
                    />
                  </CTooltip>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={12} sm={6}>
                <div className="sim-form-group">
                  <label htmlFor="duration">Duration (min)</label>
                  <CTooltip content="Total extraction time in minutes. Longer durations typically yield more extract but with diminishing returns" placement="bottom">
                    <CFormInput
                      type="number"
                      id="duration"
                      value={duration}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setDuration(Number(e.target.value))}
                      min={1}
                    />
                  </CTooltip>
                </div>
              </CCol>
              <CCol xs={12} sm={6}>
                <div className="sim-form-group">
                  <label htmlFor="timeStep">Time Step (min)</label>
                  <CTooltip content="Simulation time interval. Smaller values provide more detailed results but increase calculation time" placement="bottom">
                    <CFormInput
                      type="number"
                      id="timeStep"
                      value={timeStep}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setTimeStep(Number(e.target.value))}
                      min={0.1}
                      step={0.1}
                    />
                  </CTooltip>
                </div>
              </CCol>
            </CRow>
          </div>
        </CCol>

        {/* Solvent Selection */}
        <CCol xs={12} md={4} className="mb-4">
          <div className="sim-form-card glass-effect">
            <h3>Solvent Selection</h3>

            <CRow>
              <CCol xs={12}>
                <div className="sim-form-group">
                  <label htmlFor="solventSelect">Solvent</label>
                  <CTooltip content="The extraction solvent determines which compounds are extracted and at what efficiency" placement="left">
                    <CFormSelect
                      id="solventSelect"
                      value={selectedSolvent || ''}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setSelectedSolvent(e.target.value === '' ? null : Number(e.target.value))}
                    >
                      <option value="">Select a solvent</option>
                      {solvents.map((solvent) => (
                        <option key={solvent.id} value={solvent.id}>
                          {solvent.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CTooltip>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={12}>
                <div className="sim-form-group">
                  <label htmlFor="solventVolume">Solvent Volume (L)</label>
                  <CTooltip content="Total volume of solvent used. Higher volumes can extract more compounds but increase processing costs" placement="bottom">
                    <CFormInput
                      type="number"
                      id="solventVolume"
                      value={solventVolume}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSolventVolume(Number(e.target.value))}
                      min={0.1}
                      step={0.1}
                    />
                  </CTooltip>
                </div>
              </CCol>
            </CRow>
          </div>
        </CCol>
      </CRow>

      {/* Solutes Section */}
      <CRow className="mb-4 justify-content-center">
        <CCol xs={12} md={6}>
          <div className="sim-form-card glass-effect">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>Solutes</h3>
            </div>

            <div>
              {selectedSolutes.map((solute, index) => (
                <CRow key={index} className="mb-3 align-items-center">
                  <CCol xs={8} md={5}>
                    <div className="sim-form-group">
                      <label htmlFor={`soluteSelect-${index}`}>Solute</label>
                      <CTooltip content="The compound to be extracted from the plant material" placement="left">
                        <CFormSelect
                          id={`soluteSelect-${index}`}
                          value={solute.id || ''}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            handleSoluteChange(index, 'id', Number(e.target.value))}
                        >
                          <option value="">Select a solute</option>
                          {solutes.map((s) => (
                            <option key={s.id} value={s.id}>
                              {s.name}
                            </option>
                          ))}
                        </CFormSelect>
                      </CTooltip>
                    </div>
                  </CCol>
                  <CCol xs={8} md={4}>
                    <div className="sim-form-group">
                      <label htmlFor={`concentration-${index}`}>Concentration %</label>
                      <CTooltip content="The percentage concentration of this compound in the plant material" placement="bottom">
                        <CFormInput
                          type="number"
                          id={`concentration-${index}`}
                          value={solute.concentration}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSoluteChange(index, 'concentration', Number(e.target.value))}
                          min={0}
                          max={100}
                          step={0.1}
                        />
                      </CTooltip>
                    </div>
                  </CCol>
                  <CCol xs={4} md={2} className="d-flex align-items-end">
                    <CTooltip content="Remove this solute from the simulation" placement="bottom">
                      <CButton 
                        color="danger" 
                        onClick={() => handleRemoveSolute(index)}
                        disabled={selectedSolutes.length === 1}
                        className="mt-2"
                        size="sm"
                      >
                        Remove
                      </CButton>
                    </CTooltip>
                  </CCol>
                </CRow>
              ))}
              <CTooltip content="Add another compound to the simulation" placement="bottom">
                <CButton 
                  className="custom-add-btn mt-2"
                  onClick={handleAddSolute}
                >
                  <i className="fas fa-plus-circle me-2"></i>
                  Add Solute
                </CButton>
              </CTooltip>
            </div>
          </div>
        </CCol>
      </CRow>

      <div className="simulation-actions">
        <CTooltip content="Start the extraction simulation with the current parameters" placement="bottom">
          <CButton 
            color="primary" 
            className="run-simulation-btn"
            onClick={handleRunSimulation}
            disabled={!selectedSolvent || isLoading}
          >
            {isLoading ? 'Running...' : 'RUN SIMULATION'}
          </CButton>
        </CTooltip>
      </div>

      {/* Email Modal */}
      {showEmailModal && (
        <ResultsEmailModal
          show={showEmailModal}
          onClose={() => setShowEmailModal(false)}
          onSubmit={handleEmailSubmit}
          isSubmitting={emailSubmitting}
        />
      )}
    </CContainer>
  );
};

export default ExtractionForm;
