import React, { useState, useEffect } from 'react';

interface InventoryItem {
  id: string;
  name: string;
  currentInventory: number;
  threshold: number;
  email: string;
  unit: string;
  costPerGram?: number;
  notificationsEnabled: boolean;
}

interface InventoryUsageModalProps {
  show: boolean;
  onHide: () => void;
  item: InventoryItem | null;
  onSave: (amount: number, notes: string, cost: number) => void;
}

const InventoryUsageModal: React.FC<InventoryUsageModalProps> = ({
  show,
  onHide,
  item,
  onSave
}) => {
  const [usageAmount, setUsageAmount] = useState<number>(0);
  const [usageNotes, setUsageNotes] = useState<string>('');
  const [totalCost, setTotalCost] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Reset form when modal opens with a new item
  useEffect(() => {
    if (show && item) {
      setUsageAmount(0);
      setUsageNotes('');
      // Prefill cost based on item's costPerGram if adding inventory
      setTotalCost(0);
      setIsLoading(false);
    }
  }, [show, item]);

  // Update cost when amount changes and item has costPerGram
  useEffect(() => {
    if (usageAmount > 0 && item?.costPerGram) {
      setTotalCost(Number((usageAmount * item.costPerGram).toFixed(2)));
    }
  }, [usageAmount, item]);

  if (!show || !item) return null;

  const handleSave = () => {
    onSave(usageAmount, usageNotes, totalCost);
  };

  return (
    <div className="inventory-overlay" onClick={onHide}>
      <div className="inventory-modal" onClick={e => e.stopPropagation()}>
        <div className="inventory-modal-header">
          <h2 className="inventory-modal-title">
            {usageAmount >= 0 ? 'Add Inventory' : 'Record Usage'}: {item.name}
          </h2>
        </div>
        <div className="inventory-modal-body">
          <div className="inventory-form-group">
            <label className="inventory-label">Current Inventory: {item.currentInventory} {item.unit}</label>
          </div>
          
          <div className="inventory-form-group">
            <label className="inventory-label" htmlFor="usageAmount">
              Amount to {usageAmount >= 0 ? 'Add' : 'Subtract'} ({item.unit})
            </label>
            <div className="inventory-input-wrapper">
              <input 
                className="inventory-input"
                type="number" 
                id="usageAmount" 
                value={usageAmount}
                onChange={(e) => setUsageAmount(Number(e.target.value))}
              />
            </div>
          </div>
          
          {usageAmount > 0 && (
            <div className="inventory-form-group">
              <label className="inventory-label" htmlFor="totalCost">Total Cost ($)</label>
              <div className="inventory-input-wrapper">
                <input 
                  className="inventory-input"
                  type="number" 
                  id="totalCost" 
                  value={totalCost}
                  disabled={true}
                  onChange={(e) => setTotalCost(Number(e.target.value))}
                />
              </div>
              {totalCost > 0 && usageAmount > 0 && (
                <small className="text-muted mt-1 d-block">
                  New cost per {item.unit}: ${(totalCost / usageAmount).toFixed(4)}
                </small>
              )}
            </div>
          )}
          
          <div className="inventory-form-group inventory-full-width">
            <label className="inventory-label" htmlFor="usageNotes">Notes</label>
            <div className="inventory-input-wrapper">
              <textarea 
                className="inventory-input"
                id="usageNotes" 
                rows={3}
                value={usageNotes}
                onChange={(e) => setUsageNotes(e.target.value)}
                placeholder="Enter details about this inventory change..."
              ></textarea>
            </div>
          </div>
        </div>
        
        <div className="inventory-modal-footer">
          <button
            className="inventory-button inventory-button-secondary"
            onClick={onHide}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            className="inventory-button inventory-button-primary"
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : (usageAmount >= 0 ? 'Add Inventory' : 'Record Usage')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryUsageModal;