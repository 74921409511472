import jwtInterceoptor from './jwtInterceptor';
import { cookieUtils } from '../../utils/cookieUtils';

const getApplications = async () => {
	try {
		const apiResponse = await jwtInterceoptor.get(`${process.env.REACT_APP_API_URL}/user/api/applications/`);
		console.log('Applications', apiResponse.data.results);
		const applications = apiResponse.data.results;
		cookieUtils.set('user_applications', JSON.stringify(apiResponse.data.results));
		return applications;
	} catch (error) {
		console.log(error)
		return null;
	}
}

export {getApplications};
