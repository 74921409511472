import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  CFormInput, 
  CButton, 
  CSpinner,
  CAlert 
} from '@coreui/react';
import axios from 'axios';
import { FaRobot, FaArrowDown, FaPaperPlane } from 'react-icons/fa';

// Import the API base URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api-preview.newtoninsights.app';

interface LLMAdvisorProps {
  simulationRunId?: number;
  simulationData?: any; // The simulation parameters and results
}

interface Message {
  isUser: boolean;
  content: string;
  timestamp: string;
  interactionId?: number;
}

const LLMAdvisor: React.FC<LLMAdvisorProps> = ({ simulationRunId, simulationData }) => {
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<'untested' | 'success' | 'failure'>('untested');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  // Fetch conversation history from the API - wrapped in useCallback to avoid infinite loops
  const fetchConversationHistory = useCallback(async () => {
    if (!sessionId) return;
    
    try {
      const response = await axios.get(`${API_BASE_URL}/gandalf/history/?session_id=${sessionId}&limit=10`);
      if (response.data && Array.isArray(response.data.interactions)) {
        // Convert the API response format to our message format
        const historyMessages = response.data.interactions.map((interaction: any) => [
          {
            isUser: true,
            content: interaction.prompt,
            timestamp: new Date(interaction.created_at).toLocaleTimeString([], { 
              hour: '2-digit', minute: '2-digit', second: '2-digit'
            }),
            interactionId: interaction.id
          },
          {
            isUser: false,
            content: interaction.response,
            timestamp: new Date(interaction.updated_at).toLocaleTimeString([], { 
              hour: '2-digit', minute: '2-digit', second: '2-digit' 
            }),
            interactionId: interaction.id
          }
        ]).flat();
        
        // Only update if we have history
        if (historyMessages.length > 0) {
          setMessages(historyMessages);
        }
      }
    } catch (err) {
      console.error('Error fetching conversation history:', err);
      // Don't set error state here to avoid showing error on initial load
    }
  }, [sessionId]);

  // Initialize the session ID on component mount
  useEffect(() => {
    const newSessionId = `session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    setSessionId(newSessionId);
    
    // Add initial greeting message
    setMessages([
      { 
        isUser: false, 
        content: "Hello! I can help optimize your extraction parameters. What specifically would you like to achieve?",
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
      }
    ]);
  }, []);

  // Fetch conversation history when session ID changes or component mounts
  useEffect(() => {
    if (sessionId && messages.length <= 1) {
      fetchConversationHistory();
    }
  }, [sessionId, messages.length, fetchConversationHistory]);

  // Scroll to bottom of messages when new ones are added
  useEffect(() => {
    // Only scroll to bottom when new messages are added
    if (messages.length > 0) {
      scrollToBottomOfChat();
    }
  }, [messages]);

  // Add scroll event listener to show/hide scroll button
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      if (!container) return;
      const isScrolledUp = container.scrollHeight - container.scrollTop - container.clientHeight > 50;
      setShowScrollButton(isScrolledUp && messages.length > 3);
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [messages.length]);

  const scrollToBottomOfChat = () => {
    // Only scroll the chat container, not the entire page
    if (messagesEndRef.current) {
      // Use scrollIntoView with behavior: 'smooth' for a nicer UX
      messagesEndRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'end',
        inline: 'nearest'
      });
    }
  };

  const handleSendMessage = async () => {
    if (!userInput.trim() || simulationRunId === undefined || simulationRunId === null) return;
    
    // Store a reference to the input element to restore focus later
    const inputElement = document.activeElement;
    
    // Add user message to the UI immediately
    const newUserMessage: Message = {
      isUser: true,
      content: userInput,
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
    };
    
    setMessages(prev => [...prev, newUserMessage]);
    setUserInput('');
    setIsLoading(true);
    setError(null);
    
    try {
      // Log request details for debugging
      const apiUrl = `${API_BASE_URL}/gandalf/advice/`;
      console.log('Sending request to LLM API:', {
        endpoint: apiUrl,
        payload: {
          simulation_id: simulationRunId,
          prompt: userInput,
          session_id: sessionId,
          simulation_data_preview: '(Simulation data available)'
        }
      });
      
      // First, check if the API endpoint exists by trying a low-cost OPTIONS request
      try {
        await axios.options(apiUrl);
      } catch (optionsErr: any) {
        if (optionsErr.response?.status === 404) {
          throw new Error(`The API endpoint &apos;${apiUrl}&apos; does not exist. Please check with your backend team if the gandalf LLM service is deployed.`);
        }
      }
      
      // Send request to the LLM API
      const response = await axios.post(apiUrl, {
        simulation_id: simulationRunId,
        prompt: userInput,
        session_id: sessionId,
        simulation_data: simulationData
      });
      
      // Log successful response
      console.log('Received response from LLM API:', {
        status: response.status,
        data_preview: response.data ? '(Data received)' : '(No data)',
        advice_length: response.data?.advice ? response.data.advice.length : 0
      });
      
      // Add the LLM response to the UI
      const botResponse: Message = {
        isUser: false,
        content: response.data.advice || "I received your question, but no advice was provided in the response. This could be a backend issue.",
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
        interactionId: response.data.interaction_id
      };
      
      setMessages(prev => [...prev, botResponse]);
    } catch (err: any) {
      console.error('Error getting LLM advice:', err);
      // More detailed error logging
      console.error('Error details:', {
        message: err.message,
        status: err.response?.status,
        statusText: err.response?.statusText,
        responseData: err.response?.data,
        config: {
          url: err.config?.url,
          method: err.config?.method,
          headers: err.config?.headers
        }
      });
      
      // Provide specific error messages based on status code
      let errorMessage = '';
      if (err.response?.status === 404) {
        errorMessage = `The advice endpoint is not available (404 Not Found). Please verify that the LLM service is properly set up on the backend.`;
      } else if (err.response?.status === 500) {
        errorMessage = `The server encountered an error (500). This could be due to invalid data or a backend issue with the LLM service.`;
      } else if (!err.response) {
        errorMessage = `Network error: Could not connect to the API (${err.message}). Please check your internet connection and make sure the backend server is running.`;
      } else {
        errorMessage = err.response?.data?.detail || 
          `Failed to get a response from the advisor (${err.message}). Please try again or check the console for more details.`;
      }
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
      
      // Restore focus to the input element if it was previously focused
      if (inputElement instanceof HTMLElement) {
        setTimeout(() => {
          inputElement.focus();
        }, 0);
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !isLoading) {
      handleSendMessage();
    }
  };

  // Add a function to test the connection to the LLM API
  const testAPIConnection = async () => {
    // Store the current scroll position
    const scrollPosition = window.scrollY;
    
    setIsLoading(true);
    setError(null);
    
    try {
      // Use the history endpoint for connection testing since it's known to work
      const response = await axios.get(`${API_BASE_URL}/gandalf/history/?session_id=${sessionId}&limit=1`);
      console.log('API connection test response:', response.data);
      setConnectionStatus('success');
      setError(null);
      setMessages(prev => [
        ...prev, 
        {
          isUser: false,
          content: "LLM API connection successful! You can now ask questions about your extraction results.",
          timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        }
      ]);
    } catch (err: any) {
      console.error('Error testing LLM API connection:', err);
      setConnectionStatus('failure');
      setError(`Connection to LLM API failed. Error: ${err.message}. Please ensure the backend is running and properly configured.`);
    } finally {
      setIsLoading(false);
      
      // Restore the scroll position after a short delay
      setTimeout(() => {
        window.scrollTo({
          top: scrollPosition,
          behavior: 'auto'
        });
      }, 100);
    }
  };

  // Add a connection status indicator and test button if needed
  const renderConnectionStatus = () => {
    if (connectionStatus === 'untested' || connectionStatus === 'failure') {
      return (
        <div className="mt-3 mb-3 text-center">
          <div className="mb-2 small text-muted">
            If you&apos;re having trouble with the LLM, first try testing the connection:
          </div>
          <CButton 
            color="info" 
            size="sm"
            onClick={testAPIConnection}
            disabled={isLoading}
            style={{ color: 'white' }}
          >
            {isLoading ? <CSpinner size="sm" className="me-1" /> : null}
            Test Backend Connection
          </CButton>
        </div>
      );
    }
    return null;
  };

  if (simulationRunId === undefined || simulationRunId === null) {
    return (
      <div className="p-4 glass-effect">
        <p className="text-danger">No simulation run ID provided. Run a simulation first.</p>
      </div>
    );
  }

  return (
    <div className="llm-advisor glass-effect">
      <h4 className="advisor-title">
        <FaRobot className="mb-2 me-2 robot-icon" />
        Ask Your Extraction Wizard
      </h4>
      
      <div 
        className="advisor-messages" 
        ref={messagesContainerRef}
      >
        <div className="message-container">
          {messages.map((message, index) => (
            <div 
              key={index} 
              className={`message ${message.isUser ? 'user-message' : 'bot-message'}`}
            >
              <div className="message-content">
                {message.content}
              </div>
              <div className="message-timestamp">
                {message.timestamp}
              </div>
            </div>
          ))}
          
          {isLoading && (
            <div className="message bot-message loading-message">
              <div className="message-content d-flex align-items-center">
                <CSpinner size="sm" className="me-2" /> 
                <span>Thinking... This may take a few minutes as the LLM processes your request</span>
              </div>
            </div>
          )}
          
          {error && (
            <div className="mt-2 mb-2">
              <CAlert color="danger" dismissible>
                {error}
              </CAlert>
            </div>
          )}
          
          {renderConnectionStatus()}
          
          <div ref={messagesEndRef} />
        </div>
        
        {showScrollButton && (
          <CButton
            className="scroll-button"
            onClick={scrollToBottomOfChat}
          >
            <FaArrowDown />
          </CButton>
        )}
      </div>
      
      <div className="message-input-container d-flex mt-3">
        <CFormInput
          type="text"
          value={userInput}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Ask about your extraction parameters..."
          disabled={isLoading}
          className="advisor-input"
        />
        <CButton 
          color="primary"
          onClick={handleSendMessage}
          disabled={isLoading || !userInput.trim()}
          className="send-button ms-2"
        >
          {isLoading ? <CSpinner size="sm" /> : <FaPaperPlane />}
        </CButton>
      </div>
      <div className="advisor-footer">
        <em>Note: Responses may take several minutes as the analysis is performed.</em>
      </div>
    </div>
  );
};

export default LLMAdvisor;


