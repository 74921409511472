import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { getApplications } from '../applications';
import { cookieUtils } from '../../../utils/cookieUtils';

type SetUserType = (user: null) => void;
type NavigateType = (path: string) => void;

export const Register = async (payload: any, setUser: SetUserType, navigate: NavigateType) => {

    let toastId = toast.loading('Registering...');

    try {
        const apiResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/register/`,
          payload
        );
        if (apiResponse.data.token && apiResponse.data.token.access) {

          cookieUtils.set('tokens', JSON.stringify(apiResponse.data));
          cookieUtils.set('UserEmail', payload.get('email'));
          cookieUtils.set('UserName', payload.get('name'));

          await getApplications();
          setUser(jwt_decode(apiResponse.data.token.access));

          toast.update(toastId, { render: 'Welcome!!', type: 'success', isLoading: false });
          setTimeout(() => {
              toast.dismiss(toastId);
              //navigate('/Home');
          }, 1000);
        } else {
            console.log(apiResponse);
            toast.update(toastId, {
                render: apiResponse.data.message,
                type: 'success',
                isLoading: false,
            });
            //navigate('/');
            setTimeout(() => {
                toast.dismiss(toastId);
            }, 5000);
        }
    } catch (error: any) {
        toast.update(toastId, { render: 'Registration Failed!', type: 'error', isLoading: false });
        if (error.response?.data.errors.email) {
            for (let i = 0; i <= error.response.data.errors.email.length; i++) {
                console.log(error.response.data.errors.email[i]);
            }
        }
        setTimeout(() => {
            toast.dismiss(toastId);
        }, 5000);
    }
};
