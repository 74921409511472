import Cookies from 'js-cookie';

export const cookieUtils = {
  set: (key: string, value: any, options = {}) => {
    Cookies.set(key, JSON.stringify(value), { expires: 7, ...options });
  },

  get: (key: string) => {
    const value = Cookies.get(key);
    try {
      return value ? JSON.parse(value) : null;
    } catch {
      return value;
    }
  },

  remove: (key: string) => {
    Cookies.remove(key);
  },

  clear: () => {
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    });
  }
};
