import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  CSpinner,
  CAlert,
  CAlertHeading
} from '@coreui/react';
import SimulationResultsView from '../../../components/Digital_Twin/SimulationResultsView';
import { apiClient } from '../../../hooks/useDigitalTwin';
import '../../../assets/css/simulation.css';

const ResultsPage: React.FC = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchResults = async () => {
      if (!id) return;

      try {
        setIsLoading(true);
        const response = await apiClient.getSimulationResults(id);
        console.log("API Response:", response);
        setData(response);
        setError(null);
      } catch (err: any) {
        console.error('Error fetching results:', err);
        setError(err.response?.data?.error || err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchResults();
  }, [id]);

  if (isLoading) {
    return (
      <div className="results-loading-container">
        <CSpinner color="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="results-error-container">
        <CAlert color="danger">
          <CAlertHeading>Error loading results</CAlertHeading>
          <p>{error}</p>
        </CAlert>
      </div>
    );
  }

  return (
    <div className="results-container">
      <SimulationResultsView results={data} runId={parseInt(id || '0')} />
    </div>
  );
};

export default ResultsPage;
