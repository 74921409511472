import api, { apiClient as digitalTwinApiClient } from '../hooks/useDigitalTwin';

// Re-export the existing API client with the same interface
export const apiClient = {
  getCompounds: digitalTwinApiClient.getCompounds,
  runExtraction: digitalTwinApiClient.runExtraction,
  getSimulationResults: digitalTwinApiClient.getSimulationResults,
  checkEmail: digitalTwinApiClient.checkEmail,
  sendEmailInfo: digitalTwinApiClient.sendEmailInfo
};

export default api; 