import React from 'react';
import { CCard, CCardBody } from '@coreui/react';

interface LeaderboardPositionProps {
  position: number;
}

const LeaderboardPosition: React.FC<LeaderboardPositionProps> = ({ position }) => {
  return (
    <CCard className="metric-card glass-effect">
      <CCardBody>
        <div className="d-flex flex-column align-items-center">
          <h4 className="card-title">Newton Leaderboard Position</h4>
          <div className="metric-value display-4 text-center">
            #{position}
          </div>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default LeaderboardPosition;
