import React from 'react';
import { ResponsiveSankey } from '@nivo/sankey';

interface SankeyNode {
  id: string;
  name: string;
}

interface SankeyLink {
  source: string;
  target: string;
  value: number;
}

interface MassFlowSankeyProps {
  nodes: SankeyNode[];
  links: SankeyLink[];
}

const MassFlowSankey: React.FC<MassFlowSankeyProps> = ({ nodes, links }) => {
  // Format data for the Sankey diagram
  const data = {
    nodes: nodes.map(node => ({
      id: node.id,
      nodeColor: 'blue',
    })),
    links: links.map(link => ({
      source: link.source,
      target: link.target,
      value: link.value,
    })),
  };

  return (
    <div style={{ height: 400 }}>
      <ResponsiveSankey
        data={data}
        margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
        align="justify"
        colors={{ scheme: 'category10' }}
        nodeOpacity={1}
        nodeThickness={18}
        nodeInnerPadding={3}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
        linkOpacity={0.5}
        linkHoverOpacity={0.8}
        linkContract={3}
        enableLinkGradient={true}
        labelPosition="outside"
        labelOrientation="vertical"
        labelPadding={16}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1]] }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            translateX: 130,
            itemWidth: 100,
            itemHeight: 14,
            itemDirection: 'right-to-left',
            itemsSpacing: 2,
            itemTextColor: '#999',
            symbolSize: 14,
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};

export default MassFlowSankey;
