import React, { useState, useEffect } from 'react';

interface InventoryItem {
  id?: string;
  name: string;
  currentInventory: number;
  threshold: number;
  email: string;
  unit: string;
  costPerGram?: number;
}

interface InventoryModalProps {
  show: boolean;
  onHide: () => void;
  data: Partial<InventoryItem>;
  onSave: (data: InventoryItem) => void;
  isEditing: boolean;
  setData: (data: Partial<InventoryItem>) => void;
}

const InventoryModal: React.FC<InventoryModalProps> = ({
  show,
  onHide,
  data: initialData,
  onSave,
  isEditing,
  setData
}) => {
  const [formData, setFormData] = useState<Partial<InventoryItem>>(initialData);

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  if (!show) return null;

  const handleClose = () => {
    setFormData(initialData);
    onHide();
  };

  const handleInputChange = (field: keyof InventoryItem, value: any) => {
    const updatedData = {
      ...formData,
      [field]: value
    };
    setFormData(updatedData);
    setData(updatedData);
  };

  const handleSave = () => {
    if (formData.name && 
        formData.currentInventory !== undefined && 
        formData.threshold !== undefined && 
        formData.email && 
        formData.unit) {
      onSave(formData as InventoryItem);
      onHide();
    }
  };

  return (
    <div className="inventory-overlay" onClick={handleClose}>
      <div className="inventory-modal" onClick={e => e.stopPropagation()}>
        <div className="inventory-modal-header">
          <h2 className="inventory-modal-title">
            {isEditing ? 'Edit Item' : 'Add New Item'}
          </h2>
        </div>
        <div className="inventory-modal-body">
          <div className="inventory-form-grid">
            <div className="inventory-form-group">
              <label className="inventory-label">Item Name</label>
              <div className="inventory-input-wrapper">
                <input
                  className="inventory-input"
                  value={formData.name || ''}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                />
              </div>
            </div>
            <div className="inventory-form-group">
              <label className="inventory-label">Unit</label>
              <div className="inventory-input-wrapper">
                <input
                  className="inventory-input"
                  value={formData.unit || ''}
                  onChange={(e) => handleInputChange('unit', e.target.value)}
                />
              </div>
            </div>
            <div className="inventory-form-group">
              <label className="inventory-label">Initial Inventory</label>
              <div className="inventory-input-wrapper">
                <input
                  className="inventory-input"
                  type="number"
                  value={formData.currentInventory || ''}
                  onChange={(e) => handleInputChange('currentInventory', parseFloat(e.target.value))}
                />
              </div>
            </div>
            <div className="inventory-form-group">
              <label className="inventory-label">Stock Threshold</label>
              <div className="inventory-input-wrapper">
                <input
                  className="inventory-input"
                  type="number"
                  value={formData.threshold || ''}
                  onChange={(e) => handleInputChange('threshold', parseFloat(e.target.value))}
                />
              </div>
            </div>
            <div className="inventory-form-group">
              <label className="inventory-label">Cost per {formData.unit || 'Unit'}</label>
              <div className="inventory-input-wrapper">
                <input
                  className="inventory-input"
                  type="number"
                  step="0.01"
                  value={formData.costPerGram || ''}
                  onChange={(e) => handleInputChange('costPerGram', parseFloat(e.target.value))}
                  placeholder="0.00"
                />
              </div>
            </div>
          </div>
          <div className="inventory-form-group inventory-full-width">
            <label className="inventory-label">Notification Email</label>
            <div className="inventory-input-wrapper">
              <input
                className="inventory-input"
                type="email"
                value={formData.email || ''}
                onChange={(e) => handleInputChange('email', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="inventory-modal-footer">
          <button
            className="inventory-button inventory-button-secondary"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="inventory-button inventory-button-primary"
            onClick={handleSave}
          >
            {isEditing ? 'Save Changes' : 'Add Item'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryModal;