import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import {
  CContainer,
  CRow,
  CCol,
  CTable,
  CTableHead,
  CTableBody,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CForm,
} from '@coreui/react';
import MassFlowSankey from './MassFlowSankey';
import '../../assets/css/simulation.css';
import NewtonScoreDisplay from './NewtonScoreDisplay';
import LeaderboardPosition from './LeaderboardPosition';
import LLMAdvisor from './LLMAdvisor';
import LLMFeedback from './LLMFeedback';
import { FaDownload, FaShareAlt, FaRedo, FaPlus, FaQuestion, FaCheckCircle, FaBug } from 'react-icons/fa';
import NewtonNoBG from "../../assets/brand/NewtonInsightsBranding/NewtonNoBG.png";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Define interfaces for our data structures
interface TimeSeriesPoint {
  time: number;
  mass_extracted: number;
  concentration?: number;
  efficiency?: number;
}

interface SimulationParameters {
  temperature: number;
  pressure: number;
  particle_size: number;
  bed_porosity: number;
  effective_diffusivity: number;
  solvent_volume: number;
  duration: number;
  plant_material?: { mass: number };
  mass?: number;
}

interface CompoundResult {
  id: number;
  name: string;
  class: string;
  concentration: number;
  yield_g: number;
  efficiency_pct: number;
}

interface SimulationResultData {
  time_series: TimeSeriesPoint[];
  cannabinoid_yield_g: number;
  cannabinoid_efficiency_pct: number;
  terpene_yield_g: number;
  terpene_efficiency_pct: number;
  raffinate_mass_g: number;
  raffinate_percentage_pct: number;
  total_input_mass?: number;
  compounds?: CompoundResult[];
  simulation_logs?: string;
}

interface SimulationResultsProps {
  results: {
    parameters: SimulationParameters;
    results: SimulationResultData;
    visualizations: any;
  };
  runId?: number;
  previousRunId?: number;
}

const SimulationResultsView: React.FC<SimulationResultsProps> = ({ results, runId, previousRunId }) => {
  console.log("Results received in SimulationResultsView:", results);
  
  // Move all useState hooks to the top level - not inside conditionals
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpName, setHelpName] = useState('');
  const [helpEmail, setHelpEmail] = useState('');
  const [helpMessage, setHelpMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  
  // Add state for collapsible sections
  const [showMathProcess, setShowMathProcess] = useState(false);
  const [showExtractionChart, setShowExtractionChart] = useState(false);
  const [showComparisonFeedback, setShowComparisonFeedback] = useState(false);
  
  if (!results) {
    return <div className="loading-container"><p>Loading results...</p></div>;
  }

  // Extract data from the structure
  const parameters = results.parameters || {} as SimulationParameters;
  const simulationResults = results.results || {} as SimulationResultData;
  const visualizations = results.visualizations || {};

  // Add extreme debugging to identify exact data structure
  console.log("%c FULL BACKEND RESPONSE DUMP", "background: #ff0000; color: white; font-size: 20px");
  console.log("Raw results object:", JSON.stringify(results, null, 2));
  console.log("Parameters object:", JSON.stringify(parameters, null, 2));
  console.log("Simulation results object:", JSON.stringify(simulationResults, null, 2));

  // Generate compound results based on API data if not provided directly
  // This is a temporary function until the backend provides compound-specific data
  // Commenting out this function as it's not currently being used
  // Will be re-enabled when compound-specific data visualization is implemented
  /*
  const getCompoundResults = (): CompoundResult[] => {
    if (simulationResults.compounds && simulationResults.compounds.length > 0) {
      return simulationResults.compounds;
    }
    
    // Create mock data based on the backend logs and actual API values
    const cannabinoidYield = simulationResults.cannabinoid_yield_g || 0;
    const cannabinoidEfficiency = simulationResults.cannabinoid_efficiency_pct || 0;
    const cannabinoidEfficiencyPct = cannabinoidEfficiency < 1 ? cannabinoidEfficiency * 100 : cannabinoidEfficiency;
    
    const terpeneYield = simulationResults.terpene_yield_g || 0;
    const terpeneEfficiency = simulationResults.terpene_efficiency_pct || 0;
    const terpeneEfficiencyPct = terpeneEfficiency < 1 ? terpeneEfficiency * 100 : terpeneEfficiency;
    
    // Based on your backend logs - create mock compound data
    return [
      {
        id: 1,
        name: 'THC',
        class: 'cannabinoid',
        concentration: 10,
        yield_g: cannabinoidYield,
        efficiency_pct: cannabinoidEfficiencyPct
      },
      {
        id: 6,
        name: 'β-Myrcene',
        class: 'terpene',
        concentration: 3,
        yield_g: terpeneYield * 0.375, // 37.5% of terpene yield based on concentration ratio
        efficiency_pct: terpeneEfficiencyPct
      },
      {
        id: 5,
        name: 'Limonene',
        class: 'terpene',
        concentration: 5,
        yield_g: terpeneYield * 0.625, // 62.5% of terpene yield based on concentration ratio
        efficiency_pct: terpeneEfficiencyPct
      }
    ];
  };
  */
  
  // Calculate Newton Score - convert to percentages if they're in decimal form
  const cannabinoidForScore = simulationResults.cannabinoid_efficiency_pct || 0;
  const terpeneForScore = simulationResults.terpene_efficiency_pct || 0;
  const cannabinoidScoreValue = cannabinoidForScore < 1 ? cannabinoidForScore * 100 : cannabinoidForScore;
  const terpeneScoreValue = terpeneForScore < 1 ? terpeneForScore * 100 : terpeneForScore;
  const newtonScore = Math.round((cannabinoidScoreValue * 0.7 + terpeneScoreValue * 0.3));
  
  // Mock leaderboard position (replace with actual API call in production)
  const leaderboardPosition = 80; // Placeholder

  // Enhanced debugging - log the entire simulation results object
  console.log("Full simulation results:", simulationResults);

  // More detailed parameter logging
  console.log("Parameters object structure:", JSON.stringify(parameters, null, 2));
  
  // Extract key parameters for display
  const temperature = parameters?.temperature || 50;
  const pressure = parameters?.pressure || 200;
  const particleSize = parameters?.particle_size || 0.5;
  
  // Enhanced plant mass detection - using backend data and calculations
  let plantMass: number = 0; // Initialize with 0 to ensure it's always defined

  // Method 1: Use total_input_mass if directly available
  if (typeof simulationResults?.total_input_mass === 'number' && simulationResults.total_input_mass > 0) {
    plantMass = simulationResults.total_input_mass;
    console.log("Method 1: Using total_input_mass directly:", plantMass);
  }

  // Method 2: Calculate from raffinate_mass_g and raffinate_percentage_pct
  // If raffinate is X% of total mass, then total mass = raffinate_mass / (raffinate_percentage / 100)
  else if (simulationResults?.raffinate_mass_g && simulationResults?.raffinate_percentage_pct) {
    const raffinateMass = simulationResults.raffinate_mass_g;
    const raffinatePercentage = simulationResults.raffinate_percentage_pct;
    // Convert percentage to decimal for calculation (if needed)
    const raffinateRatio = raffinatePercentage < 1 ? raffinatePercentage : raffinatePercentage / 100;
    
    if (raffinateRatio > 0) {
      plantMass = raffinateMass / raffinateRatio;
      console.log("Method 2: Calculated from raffinate data:", plantMass);
    }
  }

  // Method 3: If we have specific yield data, we can also calculate total mass
  // From your logs: "Cannabinoid yield: 33.17g (47.38%)" and "Raffinate: 666.83g (95.26%)"
  // Total mass = cannabinoid_yield_g / (cannabinoid_efficiency_pct / 100)
  else if (simulationResults?.cannabinoid_yield_g && simulationResults?.cannabinoid_efficiency_pct) {
    const cannabinoidYield = simulationResults.cannabinoid_yield_g;
    const cannabinoidEfficiency = simulationResults.cannabinoid_efficiency_pct;
    // Convert percentage to decimal for calculation (if needed)
    const cannabinoidRatio = cannabinoidEfficiency < 1 ? cannabinoidEfficiency : cannabinoidEfficiency / 100;
    
    if (cannabinoidRatio > 0) {
      // 33.17g / 0.4738 ≈ 70g
      const estimatedTotalCannabinoidsInPlant = cannabinoidYield / cannabinoidRatio;
      // If cannabinoids are 10% of plant mass (from logs), then plant mass = estimated total cannabinoids / 0.1
      plantMass = estimatedTotalCannabinoidsInPlant / 0.1;
      console.log("Method 3: Calculated from cannabinoid data:", plantMass);
    }
  }

  // Method 4: Parse from simulation logs
  else if (simulationResults?.simulation_logs) {
    const massMatch = simulationResults.simulation_logs.match(/Plant material:.*Mass:\s*(\d+)\s*g/);
    if (massMatch && massMatch[1]) {
      plantMass = Number(massMatch[1]);
      console.log("Method 4: Found in simulation logs:", plantMass);
    }
  }

  // Method 5: Use directly from parameters if available
  else if (parameters?.plant_material?.mass) {
    plantMass = parameters.plant_material.mass;
    console.log("Method 5: Using from parameters.plant_material.mass:", plantMass);
  }
  else if (parameters?.mass) {
    plantMass = parameters.mass;
    console.log("Method 5: Using from parameters.mass:", plantMass);
  }

  // Last resort: Calculate based on the fact that cannabinoid+terpene+raffinate should = total
  // This is unreliable but better than a hardcoded value
  else {
    // Extract values that should be available
    const cannabinoidYield = simulationResults.cannabinoid_yield_g || 0;
    const terpeneYield = simulationResults.terpene_yield_g || 0;
    const raffinateYield = simulationResults.raffinate_mass_g || 0;
    
    // Calculate total mass as sum of all components
    const calculatedTotalMass = cannabinoidYield + terpeneYield + raffinateYield;
    
    // Only use this if it seems reasonable (over 100g)
    if (calculatedTotalMass > 100) {
      plantMass = calculatedTotalMass;
      console.log("Method 6: Calculated as sum of yields:", plantMass);
    }
    // Absolute last resort - use the value from logs
    else {
      plantMass = 700; // This is from your logs
      console.log("Method 7: FALLBACK to value seen in logs:", plantMass);
    }
  }

  // After all calculations, ensure plantMass has a reasonable value
  if (!plantMass || plantMass <= 0) {
    // Set an emergency fallback value that's clearly marked to help debugging
    plantMass = 700; // From logs, as a last resort
    console.log("WARNING: All calculation methods failed! Using emergency fallback value:", plantMass);
  }

  // Solvent volume and duration
  const solventVolume = parameters?.solvent_volume || 50;
  const duration = parameters?.duration || 10; // Also updating default duration from 60 to 10

  // Extract yields
  const cannabinoidYield = simulationResults.cannabinoid_yield_g || 0;
  // This is extraction efficiency - percentage of available cannabinoids that were extracted
  const cannabinoidEfficiency = simulationResults.cannabinoid_efficiency_pct || 0;
  const massRecoveryPercentage = cannabinoidEfficiency < 1 ? cannabinoidEfficiency * 100 : cannabinoidEfficiency;
  
  const terpeneYield = simulationResults.terpene_yield_g || 0;
  // This is extraction efficiency - percentage of available terpenes that were extracted
  const terpeneEfficiency = simulationResults.terpene_efficiency_pct || 0;
  const terpenePercentage = terpeneEfficiency < 1 ? terpeneEfficiency * 100 : terpeneEfficiency;
  
  // This is the percentage of total plant mass that remains after extraction
  const raffinateYield = simulationResults.raffinate_mass_g || 0;
  const raffinatePercentage = simulationResults.raffinate_percentage_pct || 0;
  
  // For debugging only
  console.log("Cannabinoid yield:", cannabinoidYield, "g with efficiency:", massRecoveryPercentage, "%");
  console.log("Terpene yield:", terpeneYield, "g with efficiency:", terpenePercentage, "%");
  console.log("Raffinate mass:", raffinateYield, "g which is", raffinatePercentage, "% of total");
  
  // Get time series data if available
  const timeSeries = simulationResults.time_series || [];

  // Create static mock time series data if none exists
  const mockTimeSeries = [
    { time: 0, mass_extracted: 0 },
    { time: 5, mass_extracted: 120 },
    { time: 10, mass_extracted: 230 },
    { time: 15, mass_extracted: 310 },
    { time: 20, mass_extracted: 380 },
    { time: 25, mass_extracted: 430 },
    { time: 30, mass_extracted: 470 },
    { time: 35, mass_extracted: 500 },
    { time: 40, mass_extracted: 520 },
    { time: 45, mass_extracted: 535 },
    { time: 50, mass_extracted: 545 },
    { time: 55, mass_extracted: 550 },
    { time: 60, mass_extracted: 552 }
  ];

  // Use the mock data if no time series exists
  const displayTimeSeries = timeSeries.length > 0 ? timeSeries : mockTimeSeries;

  // Create static chart data using the mock time series
  const staticChartData: ChartData<'line'> = {
    labels: displayTimeSeries.map(point => point.time),
    datasets: [
      {
        label: 'Mass Extracted (g)',
        data: displayTimeSeries.map(point => point.mass_extracted),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        tension: 0.1,
      }
    ],
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Extraction Process Over Time',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleReRun = () => {
    window.location.href = '/sim';
  };

  const handleNewSimulation = () => {
    window.location.href = '/sim';
  };

  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(results, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `simulation-results-${runId || 'latest'}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadRawData = () => {
    const dataToDownload = {
      simulationResults,
      parameters,
      results
    };
    const blob = new Blob([JSON.stringify(dataToDownload, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `debug-data-${runId || 'latest'}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShare = async () => {
    try {
      // Generate a shareable link
      const shareableUrl = `${window.location.origin}/sim-results/${runId || 'unknown'}`;
      
      await navigator.clipboard.writeText(shareableUrl);
      alert('Link copied to clipboard!');
    } catch (error) {
      console.error('Error sharing results:', error);
      alert('Failed to copy link to clipboard');
    }
  };

  // Update the getEfficiencyClass function to return the correct class names for progress bars
  const getEfficiencyClass = (percentage: number) => {
    if (percentage >= 70) return 'efficiency-high';
    if (percentage >= 50) return 'efficiency-medium';
    return 'efficiency-low';
  };

  // Update the getProgressBarClass function to return the correct class names for progress bars
  const getProgressBarClass = (percentage: number) => {
    if (percentage >= 70) return 'bg-high';
    if (percentage >= 50) return 'bg-medium';
    return 'bg-low';
  };

  // Handle help form submission
  const handleHelpSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Reset errors
    setEmailError('');
    setMessageError('');
    
    // Validate
    let isValid = true;
    
    if (!helpEmail || !/\S+@\S+\.\S+/.test(helpEmail)) {
      setEmailError('Please enter a valid email address');
      isValid = false;
    }
    
    if (!helpMessage.trim()) {
      setMessageError('Please enter your message');
      isValid = false;
    }
    
    if (!isValid) return;
    
    setIsSubmitting(true);
    
    try {
      // Mock API call - replace with actual implementation
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Success
      setSubmitSuccess(true);
      
      // Reset form after 2 seconds
      setTimeout(() => {
        setShowHelpModal(false);
        setHelpName('');
        setHelpEmail('');
        setHelpMessage('');
        setSubmitSuccess(false);
      }, 2000);
      
    } catch (error) {
      console.error('Error submitting help form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Mock previous run ID for LLMFeedback
  const mockPreviousRunId = 42;

  return (
    <>
      {/* Updated sticky navigation bar with logo and buttons */}
      <div className="sticky-nav">
        <div className="nav-container">
          <div className="nav-logo">
            <img src={NewtonNoBG} alt="Newton Insights" className="newton-logo" style={{ width: '120px', height: 'auto' }} />
          </div>
          <div className="nav-actions">
            <a href="/sim" className="nav-action-link" onClick={handleReRun}>
              <span className="nav-action-icon"><FaRedo /></span>
              <span className="nav-action-text">Re-Run</span>
            </a>
            <button 
              className="nav-action-link nav-action-button" 
              onClick={handleDownload}
              aria-label="Download results"
            >
              <span className="nav-action-icon"><FaDownload /></span>
              <span className="nav-action-text">Download</span>
            </button>
            <button 
              className="nav-action-link nav-action-button" 
              onClick={handleShare}
              aria-label="Share results"
            >
              <span className="nav-action-icon"><FaShareAlt /></span>
              <span className="nav-action-text">Share</span>
            </button>
            
            {/* Debug button - only shown in development environment */}
            {process.env.NODE_ENV === 'development' && (
              <button 
                className="nav-action-link nav-action-button" 
                onClick={handleDownloadRawData}
                aria-label="Download debug data"
              >
                <span className="nav-action-icon"><FaBug /></span>
                <span className="nav-action-text">Debug Data</span>
              </button>
            )}
            
            <a href="/sim" className="nav-action-link" onClick={handleNewSimulation}>
              <span className="nav-action-icon"><FaPlus /></span>
              <span className="nav-action-text">New Simulation</span>
            </a>
          </div>
        </div>
      </div>
      
      <CContainer className="simulation-container">
        {/* Header Section */}
        <div className="simulation-header">
          <h2>Extraction Simulation Results</h2>
          <p className="lead">Let&apos;s Improve Your Results</p>
        </div>

        {/* Score and Leaderboard Section */}
        <CRow className="mb-4">
          <CCol xs={12} className="text-center">
            <div className="d-flex justify-content-center">
              <div className="me-4">
                <NewtonScoreDisplay score={newtonScore} />
              </div>
              <div>
                <LeaderboardPosition position={leaderboardPosition} />
              </div>
            </div>
          </CCol>
        </CRow>

        {/* Extraction Efficiency Metrics */}
        <CRow className="mb-3 efficiency-container">
          <CCol xs={12} md={4} className="mb-4 mb-md-0">
            <div className="metric-card glass-effect">
              <h4>Cannabinoid Extraction Efficiency</h4>
              <div className={getEfficiencyClass(massRecoveryPercentage)}>
                {massRecoveryPercentage.toFixed(1)}%
              </div>
            </div>
          </CCol>

          <CCol xs={12} md={4} className="mb-4 mb-md-0">
            <div className="metric-card glass-effect">
              <h4>Terpene Extraction Efficiency</h4>
              <div className={getEfficiencyClass(terpenePercentage)}>
                {terpenePercentage.toFixed(1)}%
              </div>
            </div>
          </CCol>

          <CCol xs={12} md={4}>
            <div className="metric-card glass-effect">
              <h4>Remaining Plant Material</h4>
              <div className="metric-value">
                {raffinatePercentage.toFixed(1)}%
              </div>
            </div>
          </CCol>
        </CRow>

        {/* Efficiency Legend - Moved below efficiency tiles */}
        <div className="efficiency-legend mb-4">
          <div className="legend-item">
            <span className="legend-item-dot high"></span> Green (Good Efficiency): &gt; 70%
          </div>
          <div className="legend-item">
            <span className="legend-item-dot medium"></span> Yellow (Average Efficiency): 50% - 70%
          </div>
          <div className="legend-item">
            <span className="legend-item-dot low"></span> Red (Low Efficiency): &lt; 49%
          </div>
        </div>

        {/* NEW Extraction Wizard Card */}
        <CRow className="mb-4 justify-content-center">
          <CCol xs={8}>
            <div className="llm-advisor glass-effect text-center">
              <h3>
                <i className="fas fa-hat-wizard me-2" style={{ color: '#007AFF' }}></i>
                Your Extraction Wizard Suggests These Changes
                <i className="fas fa-hat-wizard ms-2" style={{ color: '#007AFF' }}></i>
              </h3>
              <p className="lead">Coming soon</p>
            </div>
          </CCol>
        </CRow>

        {/* Ask Your Extraction Wizard (Renamed LLM Advisor) */}
        <CRow className="mb-4 justify-content-center">
          <CCol xs={8}>
            <LLMAdvisor 
              simulationRunId={runId || 0} 
              simulationData={{
                parameters: parameters,
                results: simulationResults
              }}
            />
          </CCol>
        </CRow>

        {/* Main Content Section - Parameters and Mass Balance side by side */}
        <CRow className="mb-4">
          {/* Parameters Table */}
          <CCol xs={12} lg={6} className="mb-4 mb-lg-0">
            <div className="parameters-table glass-effect h-100">
              <h3 className='mb-5'>Simulation Parameters</h3>
              <CTable responsive>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="param-label">Temperature</CTableDataCell>
                    <CTableDataCell>{temperature} °C</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="param-label">Pressure</CTableDataCell>
                    <CTableDataCell>{pressure} bar</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="param-label">Particle Size</CTableDataCell>
                    <CTableDataCell>{particleSize} mm</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="param-label">Bed Porosity</CTableDataCell>
                    <CTableDataCell>{parameters.bed_porosity || 'N/A'}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="param-label">Effective Diffusivity</CTableDataCell>
                    <CTableDataCell>{parameters.effective_diffusivity || 'N/A'} m²/s</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="param-label">Solvent Volume</CTableDataCell>
                    <CTableDataCell>{solventVolume} L</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="param-label">Duration</CTableDataCell>
                    <CTableDataCell>{duration} minutes</CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
            </div>
          </CCol>

          {/* Mass Balance Column */}
          <CCol xs={12} lg={6}>
            <div className="mass-balance-container glass-effect h-100">
              <h3>Mass Balance</h3>
              {visualizations.mass_flow ? (
                <div className="sankey-wrapper">
                  <MassFlowSankey
                    nodes={visualizations.mass_flow.nodes}
                    links={visualizations.mass_flow.links}
                  />
                </div>
              ) : (
                <div className="bg-light p-3 rounded" style={{ height: '300px' }}>
                  <div className="text-muted text-center pt-5">
                    Sankey graph visualization coming soon
                  </div>
                </div>
              )}
            </div>
          </CCol>
        </CRow>
        
        {/* Extraction Results Summary - Centered below */}
        <CRow className="mb-4 justify-content-center">
          <CCol xs={12} md={10} lg={8}>
            <div className="glass-effect extraction-results-summary">
              <h4 className='text-center'>Extraction Results Summary</h4>
              <CTable responsive bordered hover className="extraction-results-table">
                <CTableHead>
                  <CTableRow className="table-header-row">
                    <CTableHeaderCell>Component</CTableHeaderCell>
                    <CTableHeaderCell>Mass Extracted (g)</CTableHeaderCell>
                    <CTableHeaderCell>Efficiency/Percentage</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  <CTableRow className="table-data-row">
                    <CTableDataCell><strong>Cannabinoids</strong></CTableDataCell>
                    <CTableDataCell>{cannabinoidYield.toFixed(2)} g</CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex align-items-center">
                        <div className="progress flex-grow-1 me-2" style={{ height: '8px' }}>
                          <div 
                            className={`progress-bar ${getProgressBarClass(massRecoveryPercentage)}`}
                            role="progressbar" 
                            style={{ width: `${massRecoveryPercentage}%` }} 
                            aria-valuenow={massRecoveryPercentage} 
                            aria-valuemin={0} 
                            aria-valuemax={100}
                          ></div>
                        </div>
                        <span>
                          {massRecoveryPercentage.toFixed(2)}%
                        </span>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow className="table-data-row">
                    <CTableDataCell><strong>Terpenes</strong></CTableDataCell>
                    <CTableDataCell>{terpeneYield.toFixed(2)} g</CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex align-items-center">
                        <div className="progress flex-grow-1 me-2" style={{ height: '8px' }}>
                          <div 
                            className={`progress-bar ${getProgressBarClass(terpenePercentage)}`}
                            role="progressbar" 
                            style={{ width: `${terpenePercentage}%` }} 
                            aria-valuenow={terpenePercentage} 
                            aria-valuemin={0} 
                            aria-valuemax={100}
                          ></div>
                        </div>
                        <span>
                          {terpenePercentage.toFixed(2)}%
                        </span>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow className="table-data-row">
                    <CTableDataCell><strong>Raffinate (Remaining Material)</strong></CTableDataCell>
                    <CTableDataCell>{raffinateYield.toFixed(2)} g</CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex align-items-center">
                        <div className="progress flex-grow-1 me-2" style={{ height: '8px' }}>
                          <div 
                            className="progress-bar bg-secondary"
                            role="progressbar" 
                            style={{ width: `${raffinatePercentage}%` }} 
                            aria-valuenow={raffinatePercentage} 
                            aria-valuemin={0} 
                            aria-valuemax={100}
                          ></div>
                        </div>
                        <span>{raffinatePercentage.toFixed(2)}%</span>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow className="table-data-row table-secondary">
                    <CTableDataCell><strong>Total Plant Material</strong></CTableDataCell>
                    <CTableDataCell>{plantMass.toFixed(2)} g</CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex align-items-center">
                        <div className="progress flex-grow-1 me-2" style={{ height: '8px' }}>
                          <div 
                            className="progress-bar bg-success"
                            role="progressbar" 
                            style={{ width: '100%' }} 
                            aria-valuenow={100} 
                            aria-valuemin={0} 
                            aria-valuemax={100}
                          ></div>
                        </div>
                        <span>100.00%</span>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
            </div>
          </CCol>
        </CRow>

        {/* Collapsible Sections Header */}
        <CRow className="mb-3 justify-content-center">
          <CCol xs={12} md={10} lg={8}>
            <div className="collapsible-header">
              <h3 className="text-center">Heres How It All Works</h3>
              <p className="text-center text-muted">Click on each section below to learn more about the process</p>
            </div>
          </CCol>
        </CRow>

        {/* Mathematical Process - Collapsible */}
        <CRow className="mb-3 justify-content-center">
          <CCol xs={12} md={10} lg={8}>
            <div className="collapsible-section glass-effect">
              <div 
                className="collapsible-header d-flex justify-content-between align-items-center"
                onClick={() => setShowMathProcess(!showMathProcess)}
                style={{ cursor: 'pointer' }}
              >
                <h4>Mathematical Process</h4>
                <span className="collapsible-icon">
                  {showMathProcess ? '−' : '+'}
                </span>
              </div>
              
              {showMathProcess && (
                <div className="collapsible-content mt-3">
                  <div className="math-equations">
                    <h5>Extraction Efficiency Calculation</h5>
                    <div className="equation-wrapper">
                      <div className="equation">
                        Efficiency (%) = (Mass Extracted / Total Available Mass) × 100
                      </div>
                      <div className="equation-solution">
                        Cannabinoid Efficiency = {massRecoveryPercentage.toFixed(2)}%
                      </div>
                      <div className="equation-solution">
                        Terpene Efficiency = {terpenePercentage.toFixed(2)}%
                      </div>
                    </div>
                    
                    <h5>Mass Balance Equation</h5>
                    <div className="equation-wrapper">
                      <div className="equation">
                        Total Input Mass = Extracted Mass + Raffinate Mass
                      </div>
                      <div className="equation-solution">
                        {plantMass.toFixed(2)} g = {(cannabinoidYield + terpeneYield).toFixed(2)} g + {raffinateYield.toFixed(2)} g
                      </div>
                    </div>
                    
                    <h5>Newton Score Calculation</h5>
                    <div className="equation-wrapper">
                      <div className="equation">
                        Newton Score = (Cannabinoid Efficiency × 0.7) + (Terpene Efficiency × 0.3)
                      </div>
                      <div className="equation-solution">
                        Newton Score = ({cannabinoidScoreValue.toFixed(2)} × 0.7) + ({terpeneScoreValue.toFixed(2)} × 0.3) = {newtonScore}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </CCol>
        </CRow>

        {/* Extraction Process Chart - Collapsible */}
        <CRow className="mb-3 justify-content-center">
          <CCol xs={12} md={10} lg={8}>
            <div className="collapsible-section glass-effect">
              <div 
                className="collapsible-header d-flex justify-content-between align-items-center"
                onClick={() => setShowExtractionChart(!showExtractionChart)}
                style={{ cursor: 'pointer' }}
              >
                <h4>Extraction Process Chart</h4>
                <span className="collapsible-icon">
                  {showExtractionChart ? '−' : '+'}
                </span>
              </div>
              
              {showExtractionChart && (
                <div className="collapsible-content mt-3">
                  <div className="chart-wrapper">
                    <Line options={chartOptions} data={staticChartData} />
                  </div>
                  <p className="chart-description text-muted mt-3">
                    This chart shows the mass extracted over time during the simulation process.
                    The extraction rate typically follows a curve that starts steep and gradually levels off.
                  </p>
                </div>
              )}
            </div>
          </CCol>
        </CRow>

        {/* Simulation Comparison Feedback - Collapsible */}
        <CRow className="mb-4 justify-content-center">
          <CCol xs={12} md={10} lg={8}>
            <div className="collapsible-section glass-effect">
              <div 
                className="collapsible-header d-flex justify-content-between align-items-center"
                onClick={() => setShowComparisonFeedback(!showComparisonFeedback)}
                style={{ cursor: 'pointer' }}
              >
                <h4>Simulation Comparison Feedback</h4>
                <span className="collapsible-icon">
                  {showComparisonFeedback ? '−' : '+'}
                </span>
              </div>
              
              {showComparisonFeedback && (
                <div className="collapsible-content mt-3">
                  <LLMFeedback 
                    originalRunId={previousRunId || mockPreviousRunId} 
                    newRunId={runId || (mockPreviousRunId + 1)} 
                  />
                </div>
              )}
            </div>
          </CCol>
        </CRow>

        {/* Disclaimer Footer */}
        <CRow className="mt-5 mb-4 justify-content-center">
          <CCol xs={12}>
            <div className="disclaimer-footer text-center">
              <p className="disclaimer-text">
                <strong>Disclaimer:</strong> These results are based on theoretical models and may differ in practice. 
                Use our trial to fine-tune your real-life extraction process with real data but we are not responsible 
                in any way for decisions you make.
              </p>
            </div>
          </CCol>
        </CRow>

        {/* Floating Help Button */}
        <button 
          className="floating-help-button"
          onClick={() => setShowHelpModal(true)}
          aria-label="Get Help"
        >
          <FaQuestion />
        </button>

        {/* Help Contact Modal */}
        {showHelpModal && (
          <div className="help-contact-overlay">
            <div className="help-contact-modal">
              <div className="help-contact-modal-header">
                <h3 className="help-contact-modal-title">How Can We Help?</h3>
                <button 
                  className="help-contact-close"
                  onClick={() => setShowHelpModal(false)}
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
              <div className="help-contact-modal-body">
                {submitSuccess ? (
                  <div className="text-center py-4">
                    <FaCheckCircle style={{ fontSize: '3rem', color: '#28a745', marginBottom: '1rem' }} />
                    <h4>Thank You!</h4>
                    <p>Your message has been sent. We&apos;ll get back to you soon.</p>
                  </div>
                ) : (
                  <CForm onSubmit={handleHelpSubmit}>
                    <div className="help-contact-form-group">
                      <label className="help-contact-label" htmlFor="helpName">Name (Optional)</label>
                      <input
                        type="text"
                        id="helpName"
                        className="help-contact-input"
                        value={helpName}
                        onChange={(e) => setHelpName(e.target.value)}
                        placeholder="Your name"
                      />
                    </div>
                    
                    <div className="help-contact-form-group">
                      <label className="help-contact-label" htmlFor="helpEmail">Email (Required)</label>
                      <input
                        type="email"
                        id="helpEmail"
                        className={`help-contact-input ${emailError ? 'is-invalid' : ''}`}
                        value={helpEmail}
                        onChange={(e) => setHelpEmail(e.target.value)}
                        placeholder="your.email@example.com"
                        required
                      />
                      {emailError && <div className="text-danger mt-1 small">{emailError}</div>}
                    </div>
                    
                    <div className="help-contact-form-group">
                      <label className="help-contact-label" htmlFor="helpMessage">What do you need help with?</label>
                      <textarea
                        id="helpMessage"
                        className={`help-contact-input help-contact-textarea ${messageError ? 'is-invalid' : ''}`}
                        value={helpMessage}
                        onChange={(e) => setHelpMessage(e.target.value)}
                        placeholder="Let us know what's on your mind—questions, feedback, or help optimizing your yield."
                        required
                      ></textarea>
                      {messageError && <div className="text-danger mt-1 small">{messageError}</div>}
                    </div>
                    
                    <button
                      type="submit"
                      className="help-contact-button"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Sending...' : 'Send Message'}
                    </button>
                  </CForm>
                )}
              </div>
            </div>
          </div>
        )}
      </CContainer>
    </>
  );
};

export default SimulationResultsView;
