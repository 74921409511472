import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/landing.css';
// import NewtonNoBG from '../../../assets/brand/NewtonInsightsBranding/NewtonNoBG.png';
import { useMediaQuery } from 'react-responsive';
import { motion, useInView } from "framer-motion";
import Choice1 from '../../../assets/brand/Home/Choice1.png';
import Choice2 from '../../../assets/brand/Home/Choice2.png';
import Choice4 from '../../../assets/brand/Home/Choice4.png';
import Choice5 from '../../../assets/brand/Home/Choice5.png';

const Landing = () => {
  const navigate = useNavigate();
  const isTabletLandscape = useMediaQuery({
    maxWidth: 1200,
    maxHeight: 714,
    orientation: "landscape",
  });

  const simulatorRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const simulatorInView = useInView(simulatorRef, { once: true, amount: 0.2 });
  const featuresInView = useInView(featuresRef, { once: true, amount: 0.2 });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle early access form submission
  };

  const FlowItem = ({ color, title, description, delay, bgImage, isLast }: {
    color: string,
    title: string,
    description: string,
    delay: number,
    bgImage: string,
    isLast?: boolean
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const inView = useInView(ref, { once: true, amount: 0.2 });

    return (
      <div className="flow-item-container" ref={ref}>
        <motion.div
          className="connector"
          initial={{ height: 0 }}
          animate={{ height: inView ? "100%" : 0 }}
          transition={{ duration: 0.3, delay: delay * 0.3 }}
        />
        <motion.div
          className="flow-item"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
          transition={{ duration: 0.2, delay: delay * 0.3 + 0.1 }}
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <div className="dot" style={{ backgroundColor: color }} />
          <div className="content">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </motion.div>
        {!isLast && <div className="arrow-down" />}
      </div>
    );
  };

  return (
    <div className="landing-container">
      <nav className="nav-container">
        <div className="header-group">
          <div
            className="justified-container"
          >
            <div className="justified-line">
              <h2 style={{
                fontSize: "24px",
                ...(isTabletLandscape ? { fontSize: '16px' } : {}),
                fontFamily: "Expansiva, sans-serif",
                margin: 0
              }}>
                Newton
              </h2>
            </div>
            <div className="justified-line">
              <p style={{
                marginLeft: "35px",
                fontSize: "16px",
                ...(isTabletLandscape ? { fontSize: '10px' } : {})
              }}>
                Insights
              </p>
            </div>
          </div>
        </div>

        <div className="nav-links">
          <a href="/contact" className="contact-link">Contact Us</a>
          <button
            className="login-button"
            onClick={() => navigate('/login')}
          >
            Login
          </button>
        </div>
      </nav>

      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">
            <span style={{ fontSize: '1.5rem', fontWeight: 'normal', display: 'block', marginBottom: '0.5rem' }}>One Platform</span>
            <span style={{ color: '#3e3d3d', fontWeight: 'bold', display: 'inline-block', marginBottom: '0.5rem' }}>Automate Data, Monitor Cannabinoids & Optimize Outcomes</span>
            <span style={{ color: '#007AFF', fontWeight: 'bold', display: 'inline-block' }}>All in Real-Time</span>
          </h1>

          <p className="hero-subtitle" style={{ color: '#333', fontSize: '1.4rem', marginTop: '1.5rem', marginBottom: '3rem' }}>
            Say Goodbye to manual data entry, guesswork and reactive decisions
          </p>

          <motion.div
            className="simulator-container"
            ref={simulatorRef}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: simulatorInView ? 1 : 0, y: simulatorInView ? 0 : 50 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            {/* Placeholder for simulator/demo */}
            <img
              src="/path-to-simulator-image.png"
              alt="Newton Insights Platform Demo"
              style={{ width: '100%', height: 'auto' }}
            />
          </motion.div>

          <form className="early-access-form" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              className="form-input"
              required
            />
            <button type="submit" className="landing-submit-button">
              Get Early Access to Newton
            </button>
          </form>

          <p className="bottom-text">
            Newton automates data collection, delivering full visibility for executives to drive strategy while equipping operators with real-time insights for efficiency. Reduce manual entry and make informed decisions with confidence.
          </p>
        </div>
      </section>

      <motion.section
        className="features-section"
        ref={featuresRef}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: featuresInView ? 1 : 0, y: featuresInView ? 0 : 50 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <div className="features-content">
          <h2>Ship faster with powerful developer tools</h2>
          <p>Built for developers to integrate seamlessly with your workflow</p>
          {/* Add more feature content as needed */}
        </div>
      </motion.section>

      <section className="flow-section">
        <h2>Complete Visibility & Control Over Your Supply Chain</h2>

        <div className="flow-container">
          <FlowItem
            color="#4CAF50"
            title="Cultivation"
            description="Optimize plant health & maximize yield efficiency with AI insights."
            delay={0}
            bgImage={Choice1}
          />

          <FlowItem
            color="#2196F3"
            title="QA/QC"
            description="Ensure raw materials meet strict quality standards before production."
            delay={0.5}
            bgImage={Choice4}
          />

          <FlowItem
            color="#FF9800"
            title="Extracto"
            description="Monitor and improve extraction in real time for higher yields."
            delay={1}
            bgImage={Choice2}
          />

          <FlowItem
            color="#FFC107"
            title="Post-Finished"
            description="Guarantee consistency & quality in final product formulations."
            delay={1.5}
            bgImage={Choice5}
            isLast={true}
          />
        </div>
      </section>
    </div>
  );
};

export default Landing;
