import React from 'react';

type FailedComponent = 'Calibration' | 'Diagnostic' | 'Solvent Reference';

interface ErrorModalProps {
  visible: boolean;
  title: string;
  message: string;
  failedComponent: FailedComponent;
  retryCount: number;
  onClose: () => void;
  onRetry: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  visible,
  title,
  message,
  failedComponent,
  retryCount,
  onClose,
  onRetry,
}) => {
  if (!visible) return null;

  return (
    <div className="parameter-overlay" onClick={onClose}>
      <div className="parameter-modal" onClick={e => e.stopPropagation()}>
        <div className="parameter-modal-header">
          <h2 className="parameter-modal-title">{title}</h2>
        </div>

        <div className="parameter-modal-body">
          <p style={{ textAlign: 'center', fontSize: '1rem', color: '#475569' }}>
            {message}
          </p>
        </div>

        <div className="parameter-modal-footer">
          <button
            className="parameter-button parameter-button-secondary"
            onClick={onClose}
          >
            Close
          </button>
          {retryCount < 2 && (
            <button
              className="parameter-button parameter-button-primary"
              onClick={() => {
                onClose();
                onRetry();
              }}
            >
              Retry {failedComponent}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ErrorModal); 