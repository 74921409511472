import React from 'react';
import '../../assets/css/managerdashboard.css';
import {
	CButton,
	CContainer,
	CCardTitle,
	CTooltip,
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import DigitalTwin from '../../assets/brand/ManagerDashboard/DigitalTwin.png';
import Inventory from '../../assets/brand/ManagerDashboard/Inventory.png';
import PnL from '../../assets/brand/ManagerDashboard/P&L.png';
import useUserProfile from '../../hooks/useUserProfile';

const ManagerDashboard = () => {
	const navigate = useNavigate();

	const { userProfile } = useUserProfile();

	return (
		<CContainer fluid>
			{userProfile?.role === "Company Admin" && (
				<div className="admin-link-container">
					<a href="/InputParametersDnD" className="admin-link">Customize Run Sheet Fields</a>
				</div>
			)}
			<p className="main-choice manager-heading">
				Guesswork Removed—Oversee & Optimize Your Business
			</p>
			<div className="manager-subheading">
				Gain Full Visibility, Monitor Key Metrics & Streamline Operations in Real Time
			</div>
			<div className="manager-container">
				<div className="manager-cards-container">
					{[
						{
							image: Inventory,
							title: 'Inventory',
							text: 'Inventory provides real-time data transfer, product usage tracking, and cost analysis, all feeding directly into your digital dashboard for real-time insights and decision-making. Gain direct visibility across your complete cultivation operations, and resource utilization.',
							navigateTo: '/manager-dashboard/inventory',
						},
						{
							image: DigitalTwin,
							title: 'Digital Twin',
							text: 'Extracto provides real-time monitoring of your extraction process. Track THC levels, temperature, pressure, and other critical metrics to optimize your runs efficiently. Gain direct visibility inside your extraction booth.',
							navigateTo: '/ExtractoPredictionDashboard',
							comingSoon: true,
						},
						{
							image: PnL,
							title: 'P&L',
							text: 'Post-Processing provides real-time insights for optimizing and monitoring the refinement of extracted materials, ensuring efficiency and consistency across all processes. Gain insights into material transitions, quality control, and preparation for the next stage.',
							navigateTo: '/',
							comingSoon: true,
						},
						{
							image: Inventory,
							title: 'Comparison',
							navigateTo: '/',
							comingSoon: true,
						},
					].map(({ image, title, text, navigateTo, comingSoon }, index) => (
						<div key={index} className="manager-card-col">
							<CCardTitle className="manager-card-title">
								{title}
							</CCardTitle>
							{text ? (
								<CTooltip
									content={<span style={{ fontSize: '11px', padding: '10px 0px' }}>{text}</span>}
									placement="right"
								>
									<div className="manager-card">
										<img
											src={image}
											className="manager-card-image"
											alt={title}
										/>
										<div className="manager-button-container">
											<button
												className=" manager-button"
												color={"primary"}
												style={{
													opacity: comingSoon ? 0.9 : 1,
													backgroundColor: comingSoon ? 'gray' : undefined,
													// borderColor: comingSoon ? 'gray' : undefined,
												}}
												onClick={comingSoon ? undefined : () => navigate(navigateTo)}
											>
												{comingSoon ? 'Coming Soon' : `Go to ${title}`}
											</button>
										</div>
									</div>
								</CTooltip>
							) : (
								<div className="manager-card">
									<img
										src={image}
										className="manager-card-image"
										alt={title}
									/>
									<div className="manager-button-container">
										<CButton
											className="centeralized no-margin manager-button"
											color={"primary"}
											style={{
												opacity: comingSoon ? 0.9 : 1,
												backgroundColor: comingSoon ? 'gray' : undefined,
												borderColor: comingSoon ? 'gray' : undefined,
											}}
											onClick={comingSoon ? undefined : () => navigate(navigateTo)}
										>
											{comingSoon ? 'Coming Soon' : `Go to ${title}`}
										</CButton>
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</CContainer>
	);
};

export default ManagerDashboard;
