import React, { createContext, useState } from 'react';

const defaultFields = [
    { id: 4, content: 'Input Weight (Lbs)', locked: false, type: 'Number' },
    { id: 6, content: 'Pressure 2 (psi)', locked: false, type: 'Number' },
    { id: 7, content: 'Temperature 2 (°C)', locked: false, type: 'Number' },
];

const initialSelectedFields = [
    { id: 1, content: 'Strain Name', locked: true, type: 'Text' },
    { id: 2, content: 'Pressure (psi)', locked: true, type: 'Number' },
    { id: 3, content: 'Temperature (°C)', locked: true, type: 'Number' },
    { id: 5, content: 'Operator', locked: true, type: 'Dropdown' },
];

export const FieldsContext = createContext<any>({
    setSelectedFields: () => {},
});

export const FieldsProvider = ({ children }: { children: React.ReactNode }) => {
    const [fields, setFields] = useState<any>(defaultFields);
    const [selectedFields, setSelectedFields] = useState<any>(initialSelectedFields);

    return (
        <FieldsContext.Provider
            value={{
                fields,
                setFields,
                selectedFields,
                setSelectedFields,
                defaultFields,
                initialSelectedFields
            }}
        >
            {children}
        </FieldsContext.Provider>
    );
};
