import React from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CListGroup,
  CListGroupItem,
} from '@coreui/react';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import './styles.css';

interface ConnectHardwareProps {
  onComplete: () => void;
  onSkip: () => void;
}

const ConnectHardware: React.FC<ConnectHardwareProps> = ({ onComplete, onSkip }) => {
  const [completedSteps, setCompletedSteps] = React.useState<number[]>([]);

  const steps = [
    {
      id: 1,
      title: 'Connect the sensor to your extraction system',
      description: 'Ensure all connections are secure and properly aligned.'
    },
    {
      id: 2,
      title: 'Run the fibers through the appropriate pathways',
      description: 'Carefully route the fibers to avoid any pinch points or sharp bends.'
    },
    {
      id: 3,
      title: 'Plug in the sensor securely',
      description: "Connect to a stable power source where it won't be accidentally disconnected."
    },
    {
      id: 4,
      title: 'Test the system',
      description: 'Verify all connections and ensure the system is working properly.'
    }
  ];

  const toggleStep = (stepId: number) => {
    setCompletedSteps(prev =>
      prev.includes(stepId)
        ? prev.filter(id => id !== stepId)
        : [...prev, stepId]
    );
  };

  return (
    <div className="connect-hardware">
      <div className="step-header">
        <h2>Connect Your Hardware</h2>
        <p className="estimated-time">Estimated Time: 5-10 minutes</p>
      </div>

      <CCard className="setup-card">
        <CCardBody>
          <CCardText>
            Follow these steps to properly connect your hardware. Click each step to mark it as complete.
          </CCardText>

          <CListGroup flush>
            {steps.map((step) => (
              <CListGroupItem
                key={step.id}
                className={`setup-step ${completedSteps.includes(step.id) ? 'completed' : ''}`}
                onClick={() => toggleStep(step.id)}
              >
                <div className="step-content">
                  <div className="step-icon">
                    {completedSteps.includes(step.id) ? (
                      <FaCheckCircle className="check-icon" />
                    ) : (
                      <FaCircle className="circle-icon" />
                    )}
                  </div>
                  <div className="step-text">
                    <h4>{step.title}</h4>
                    <p>{step.description}</p>
                  </div>
                </div>
              </CListGroupItem>
            ))}
          </CListGroup>
        </CCardBody>
      </CCard>

      <div className="navigation-buttons">
      <CButton
          color="dark"
          variant="ghost"
          onClick={onSkip}
          className="skip-button"
          style={{ backgroundColor: '#f0f0f0' }}
        >
          Skip for now
        </CButton>
        <CButton
          color="dark"
          onClick={onComplete}
          disabled={completedSteps.length < steps.length}
        >
          Continue
        </CButton>
      </div>
    </div>
  );
};

export default ConnectHardware;
