import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

interface ScheduleAutomationModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (time: string, interval: string) => void;
}

interface FormErrors {
  time?: string;
  interval?: string;
}

const ScheduleAutomationModal: React.FC<ScheduleAutomationModalProps> = ({
  show,
  onClose,
  onSave,
}) => {
  const [time, setTime] = useState<string>("");
  const [interval, setInterval] = useState<string>("");
  const [errors, setErrors] = useState<FormErrors>({});
  const isTablet = useMediaQuery({ maxWidth: 992 });

  const handleSave = () => {
    const newErrors: FormErrors = {};

    if (!time) {
      newErrors.time = "Please set the time";
    }
    if (!interval) {
      newErrors.interval = "Please set the interval";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      onSave(time, interval);
    }
  };

  if (!show) return null;

  return (
    <div className="schedule-overlay" onClick={onClose}>
      <div className="schedule-modal" onClick={e => e.stopPropagation()}>
        <div className="schedule-modal-header">
          <h2 className="schedule-modal-title">Set Automation Schedule</h2>
        </div>

        <div className="schedule-modal-body">
          <div className="schedule-form-group mx-3">
            <label className="schedule-label" htmlFor="automationTime">
              Time
            </label>
            <input
              type="time"
              id="automationTime"
              className="schedule-input"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              style={isTablet ? { height: '35px', fontSize: '14px' } : {}}
            />
            {errors?.time && (
              <p className="error-message">{errors.time}</p>
            )}
          </div>

          <div className="schedule-form-group mx-3">
            <label className="schedule-label" htmlFor="automationInterval">
              Interval
            </label>
            <select
              id="automationInterval"
              className="schedule-select"
              value={interval}
              onChange={(e) => setInterval(e.target.value)}
              style={isTablet ? { height: '35px', fontSize: '14px' } : {}}
            >
              <option value="">Select an interval</option>
              <option value="daily">Daily</option>
            </select>
            {errors?.interval && (
              <p className="error-message">{errors.interval}</p>
            )}
          </div>
        </div>

        <div className="schedule-modal-footer">
          <button
            className="schedule-button schedule-button-secondary"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="schedule-button schedule-button-primary"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleAutomationModal;
