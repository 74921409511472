import React, { useState, useEffect, useCallback } from 'react';
import {
  CFormLabel,
  CFormTextarea,
  CAlert,
  CButton,
  CSpinner
} from '@coreui/react';
import axios from 'axios';

// Import the API base URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api-preview.newtoninsights.app';

interface LLMFeedbackProps {
  originalRunId?: number;
  newRunId: number;
}

const LLMFeedback: React.FC<LLMFeedbackProps> = ({ originalRunId, newRunId }) => {
  const [successScore, setSuccessScore] = useState<number>(5);
  const [notes, setNotes] = useState<string>('');
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [message, setMessage] = useState<string>('');
  const [interactionId, setInteractionId] = useState<number | null>(null);

  // Wrap fetchInteractionId in useCallback to prevent infinite loops
  const fetchInteractionId = useCallback(async () => {
    try {
      // For now, we'll use a mock interaction ID based on the run IDs
      // In a real implementation, you'd fetch this from your backend
      const mockInteractionId = newRunId * 10000 + (originalRunId || 0);
      setInteractionId(mockInteractionId);
    } catch (error) {
      console.error('Error fetching interaction ID:', error);
      setStatus('error');
      setMessage('Failed to fetch interaction data. Please try again later.');
    }
  }, [newRunId, originalRunId, setStatus, setMessage]);

  // Fetch the most recent interaction ID for these runs
  useEffect(() => {
    if (originalRunId !== undefined && originalRunId !== null && newRunId !== undefined && newRunId !== null) {
      fetchInteractionId();
    }
  }, [originalRunId, newRunId, fetchInteractionId]);

  // If we don't have both run IDs, don't render anything
  if (originalRunId === undefined || originalRunId === null) {
    return null;
  }

  const handleSubmitFeedback = async () => {
    if (!interactionId) {
      setStatus('error');
      setMessage('No interaction ID found. Cannot submit feedback.');
      return;
    }

    setStatus('loading');
    
    try {
      // Send feedback to the API but don't store the response since we don't need it
      await axios.post(`${API_BASE_URL}/gandalf/feedback/`, {
        interaction_id: interactionId,
        rating: successScore,
        comments: notes
      });
      
      setStatus('success');
      setMessage('Feedback submitted successfully! Thank you for helping improve our recommendations.');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setStatus('error');
      setMessage('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <div className="feedback-container glass-effect p-4">
      <h3 className="text-center mb-4">Rate Parameter Effectiveness</h3>
      
      {status !== 'idle' && status !== 'loading' && (
        <CAlert color={status === 'success' ? 'success' : 'danger'} className="mb-3">
          {message}
        </CAlert>
      )}

      <div className="mb-4">
        <CFormLabel htmlFor="success-score" className="mb-2">
          How effective were the parameter changes? (1-10)
        </CFormLabel>
        <div className="d-flex align-items-center">
          <span className="me-2 text-muted">1</span>
          <input
            type="range"
            className="form-range custom-range"
            id="success-score"
            min="1"
            max="10"
            step="1"
            value={successScore}
            onChange={(e) => setSuccessScore(parseInt(e.target.value))}
            disabled={status === 'loading' || status === 'success'}
          />
          <span className="ms-2 text-muted">10</span>
        </div>
        <div className="text-center mt-2">
          <span className="score-display">
            Selected: <strong>{successScore}</strong>
          </span>
        </div>
      </div>
      
      <div className="mb-4">
        <CFormLabel htmlFor="feedback-notes" className="mb-2">Notes on results (optional)</CFormLabel>
        <CFormTextarea
          id="feedback-notes"
          rows={3}
          value={notes}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
          placeholder="What improvements did you notice? Any unexpected effects?"
          disabled={status === 'loading' || status === 'success'}
          className="feedback-textarea"
        />
      </div>
      
      <div className="text-center">
        <CButton 
          color="primary" 
          onClick={handleSubmitFeedback}
          className="feedback-submit-btn"
          disabled={status === 'loading' || status === 'success'}
        >
          {status === 'loading' ? (
            <>
              <CSpinner size="sm" className="me-2" />
              Submitting...
            </>
          ) : (
            'Submit Feedback'
          )}
        </CButton>
      </div>
    </div>
  );
};

export default LLMFeedback;

