import axios from 'axios';
import { cookieUtils } from '../utils/cookieUtils'; // You may need to adjust this import path

// Create a base axios instance with the correct base URL
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/digital-twin`,
  headers: {
    'Content-Type': 'application/json'
  }
});

interface Solute {
    id: number;
    concentration: number;
}

interface SimulationParameters {
    plant_material: {
        strain: string;
        mass: number;
        particle_size: number;
        bed_porosity: number;
        effective_diffusivity: number;
        cannabinoid_percentage: number;
    };
    solvent_id: number;
    solvent_volume: number;
    temperature: number;
    pressure: number;
    duration: number;
    time_step: number;
    solutes: Solute[];
}

// Simple API client with consistent error handling
export const apiClient = {
  getCompounds: async () => {
    try {
      const response = await api.get('/compounds/');
      return response.data;
    } catch (error) {
      console.error('Error fetching compounds:', error);
      throw error;
    }
  },

  runExtraction: async (parameters: SimulationParameters) => {
    try {
      console.log('Sending parameters to /run-extraction/:', parameters);
      const response = await api.post('/run-extraction/', parameters);
      console.log('Received response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error running extraction:', error);
      throw error;
    }
  },

  getSimulationResults: async (runId: string | number) => {
    try {
      const response = await api.get(`/simulation-results/${runId}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching simulation results:', error);
      throw error;
    }
  },

  checkEmail: async () => {
    try {
      const email = cookieUtils.get('simulation_email');
      const params = email ? { email } : {};
      
      const response = await api.get(`/check-submission/`, { params });
      console.log('Received response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error checking email:', error);
      throw error;
    }
  },

  sendEmailInfo: async (data: { name: string; email: string; company: string }) => {
    try {
      const response = await api.post('/submit-form/', data);
      cookieUtils.set('simulation_email', data.email);
      return response.data;
    } catch (error) {
      console.error('Error submitting email information:', error);
      throw error;
    }
  }
};

export default api;
