import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react'
import { FieldsContext } from '../../../../contexts/InputParameters/FieldsProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useUserProfile from '../../../../hooks/useUserProfile';
import jwtInterceoptor from '../../../../views/shared/jwtInterceptor';
import { useInputParametersDnD } from '../../../../hooks/useInputParametersDnD';
import { cookieUtils } from '../../../../utils/cookieUtils';

interface Field {
    id: string;
    type: string;
    content: string;
    locked: boolean;
}

interface PayloadField {
    locked: boolean;
    content: string;
    type: string;
    custom_type: boolean;
    additional_info?: string[];
}

const InputParametersDnD = () => {
    const { fields, setFields, selectedFields, setSelectedFields, defaultFields, initialSelectedFields } = useContext(FieldsContext);
    const navigate = useNavigate();
    const { userProfile } = useUserProfile();
    const isAdmin = userProfile?.role === 'Company Admin';
    const [searchTerm, setSearchTerm] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState<string[]>(['', '']);
    const [isInventoryItem, setIsInventoryItem] = useState(false);

    const {
        newFieldLabel,
        setNewFieldLabel,
        newFieldType,
        setNewFieldType,
        isRequired,
        setIsRequired,
        fetchInputParameters,
        handleAddCustomField
    } = useInputParametersDnD({
        setFields,
        setSelectedFields,
        defaultFields,
        initialSelectedFields
    });

    const [fieldToDelete, setFieldToDelete] = useState<number | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // Automatically select locked fields on component mount
    useEffect(() => {
        const initialSelectedFields = selectedFields;
        setSelectedFields(initialSelectedFields);
    }, [fields, selectedFields, setSelectedFields]);

    // Update the useEffect for API call
    useEffect(() => {
        const initializeData = async () => {
            const dataLength = await fetchInputParameters();
            if (dataLength === 0) {
                // If no data exists, call handleSave to initialize with default values
                await handleSaveCopy();
                await fetchInputParameters();
            }
        };

        initializeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update cookies whenever selectedFields changes
    useEffect(() => {
      if (selectedFields) {
        cookieUtils.set("preview-selected-fields", selectedFields);
      }
    }, [selectedFields]);

    const onDragEnd = (result: any) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        // Source and destination arrays (fields or selectedFields)
        const sourceList = source.droppableId === 'fields' ? fields : selectedFields;
        const destinationList = destination.droppableId === 'fields' ? fields : selectedFields;

        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return;
        }

        // Moving within the same list (fields or selectedFields)
        if (source.droppableId === destination.droppableId) {
            const updatedList = Array.from(sourceList);
            const [movedItem] = updatedList.splice(source.index, 1);
            updatedList.splice(destination.index, 0, movedItem);

            source.droppableId === 'fields' ? setFields(updatedList) : setSelectedFields(updatedList);
        } else {
            const sourceClone = Array.from(sourceList);
            const destinationClone = Array.from(destinationList);

            const [movedItem] = sourceClone.splice(source.index, 1);
            // Update the locked status based on destination
            const updatedMovedItem: Field = {
                ...movedItem as Field,
                locked: destination.droppableId === 'selectedFields'
            };
            destinationClone.splice(destination.index, 0, updatedMovedItem);

            source.droppableId === 'fields' ? setFields(sourceClone) : setSelectedFields(sourceClone);
            destination.droppableId === 'fields' ? setFields(destinationClone) : setSelectedFields(destinationClone);

            // After drag operations complete, update cookies
            if (destination.droppableId === 'selectedFields') {
                cookieUtils.set("preview-selected-fields", JSON.stringify(destinationClone));
            }
        }
    };

    const handleOptionChange = (index: number, value: string) => {
        const newOptions = [...dropdownOptions];
        newOptions[index] = value;
        setDropdownOptions(newOptions);
    };

    const addNewOption = () => {
        setDropdownOptions([...dropdownOptions, '']);
    };

    const handleAddField = async () => {
        if (!newFieldLabel) {
            toast.error('Please provide a name for the field.');
            return;
        }

        if (isInventoryItem) {
            // For inventory items, only need the name
            try {
                await jwtInterceoptor.post(
                    `${process.env.REACT_APP_API_URL}/user/api/inventory/`,
                    {
                        name: newFieldLabel,
                    }
                );
                toast.success('Inventory item added successfully!', { autoClose: 1000 });
                setNewFieldLabel('');
                setIsInventoryItem(false);
                await fetchInputParameters();
                return;
            } catch (error) {
                toast.error('Failed to add inventory item');
                console.error('Error adding inventory item:', error);
                return;
            }
        }

        // Regular field logic (only runs if not an inventory item)
        if (!newFieldType) {
            toast.error('Please provide a type for the custom field.');
            return;
        }

        let payload: PayloadField[] = [{
            locked: false,
            content: newFieldLabel,
            type: (newFieldType === "Dropdown") ? "Dropdown(Custom)" : newFieldType,
            custom_type: true,
        }];

        // Add additional_info for dropdown options
        if (newFieldType === "Dropdown") {
            const filteredOptions = dropdownOptions.filter(opt => opt.trim() !== '');
            if (filteredOptions.length < 2) {
                toast.error('Please provide at least 2 options for dropdown');
                return;
            }
            
            payload[0] = {
                ...payload[0],
                additional_info: filteredOptions
            };
        }

        await handleAddCustomField(payload);
        // Reset dropdown options after adding
        setDropdownOptions(['', '']);
    }

    // Save fields and selectedFields to cookies
  const handleSave = async () => {
        const combinedFields = [...selectedFields, ...fields];
        const response = await jwtInterceoptor.put(
          `${process.env.REACT_APP_API_URL}/user/update-company-fields/`,
           combinedFields  // Sending combined array directly
        )
        setSelectedFields(response?.data?.data);
        toast.success("Layout saved successfully!", { autoClose: 1000 });
        cookieUtils.remove("preview-mode");
        navigate("/InputParameters");
    };

    const handleSaveCopy = async () => {
        const combinedFields = [...selectedFields, ...fields];
        const response = await jwtInterceoptor.put(
            `${process.env.REACT_APP_API_URL}/user/update-company-fields/`,
            combinedFields  // Sending combined array directly
        );
        setSelectedFields(response?.data?.data);
        cookieUtils.remove("preview-mode");
    };

    // Handle the preview navigation
    const handlePreview = () => {
        cookieUtils.set("preview-mode", true);
        navigate('/InputParameters');
    };

    const handleDeleteField = async (fieldId: string | number) => {
        try {
            await jwtInterceoptor.delete(
                `${process.env.REACT_APP_API_URL}/user/input-parameter-fields/delete/`,
                { data: { id: fieldId } }
            );
            toast.success('Field deleted successfully!', { autoClose: 1000 });
            await fetchInputParameters();
        } catch (error) {
            toast.error('Failed to delete field');
            console.error('Error deleting field:', error);
        }
    };

    const handleDeleteConfirmation = (fieldId: number) => {
        setFieldToDelete(fieldId);
        setShowDeleteModal(true);
    };

    const confirmDelete = () => {
        if (fieldToDelete !== null) {
            handleDeleteField(fieldToDelete);
        }
        setShowDeleteModal(false);
        setFieldToDelete(null);
    };

    // Add this new function to filter and sort fields based on search
    const getFilteredFields = () => {
        if (!searchTerm) return fields;

        return [...fields].sort((a, b) => {
            const aStartsWith = a.content.toLowerCase().startsWith(searchTerm.toLowerCase());
            const bStartsWith = b.content.toLowerCase().startsWith(searchTerm.toLowerCase());
            const aIncludes = a.content.toLowerCase().includes(searchTerm.toLowerCase());
            const bIncludes = b.content.toLowerCase().includes(searchTerm.toLowerCase());

            if (aStartsWith && !bStartsWith) return -1;
            if (!aStartsWith && bStartsWith) return 1;
            if (aIncludes && !bIncludes) return -1;
            if (!aIncludes && bIncludes) return 1;
            return 0;
        }).filter(field =>
            field.content.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    return (
        <div className="container-fluid mt-3">
            <div className="text-center mb-4">
                <h1>Select Fields For Entering Post Purge Information</h1>
                <p className="text-muted">Drag & drop to add fields</p>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <div className="row justify-content-center mx-5">
                    {isAdmin && (
                        <aside className="col-md-4 mx-2">
                            <div className="field-library-container">
                                <div className="field-search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Fields..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div>
                                  <h2 className="text-center mt-1">Field Library</h2>
                                </div>
                                <div className="field-library-container">
                                    <Droppable droppableId="fields">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="field-section field-library"
                                            >
                                                {getFilteredFields()?.map((field: Field, index: number) => (
                                                    <Draggable
                                                        key={field.id.toString()}
                                                        draggableId={field.id.toString()}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="draggable-field bg-light"
                                                                title={`${field.type}`}
                                                            >
                                                              <p className="mb-0">{field.content}</p>
                                                                {isAdmin && (
                                                                  <button
                                                                  className="btn btn-link text-danger p-0 ms-2 delete-btn"
                                                                  onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDeleteConfirmation(Number(field.id));
                                                                        }}
                                                                    >
                                                                        ×
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>

                                <div className="custom-field-section p-3 border rounded">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5 className="mb-0">Add {isInventoryItem ? 'Inventory Item' : 'Custom Field'}</h5>
                                    </div>
                                    <div className="custom-field-inputs d-flex flex-column gap-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={isInventoryItem ? "Inventory Item Name" : "Field Label"}
                                            value={newFieldLabel}
                                            onChange={(e) => setNewFieldLabel(e.target.value)}
                                        />
                                        
                                        {/* Only show type selector if not an inventory item */}
                                        {!isInventoryItem && (
                                            <select
                                                className="form-control"
                                                value={newFieldType}
                                                onChange={(e) => setNewFieldType(e.target.value)}
                                            >
                                                <option value="">Select Type</option>
                                                <option value="Text">Text</option>
                                                <option value="Number">Number</option>
                                                <option value="Mixed">Mixed</option>
                                                <option value="Dropdown">Dropdown</option>
                                            </select>
                                        )}

                                        {/* Only show dropdown options if dropdown type is selected and not an inventory item */}
                                        {newFieldType === "Dropdown" && !isInventoryItem && (
                                            <div className="dropdown-options d-flex flex-column gap-2">
                                                {dropdownOptions.map((option, index) => (
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Option ${index + 1}`}
                                                        value={option}
                                                        onChange={(e) => handleOptionChange(index, e.target.value)}
                                                    />
                                                ))}
                                                <button 
                                                    type="button"
                                                    className="btn inventory-button inventory-button-secondary"
                                                    onClick={addNewOption}
                                                >
                                                    Add Another Option
                                                </button>
                                            </div>
                                        )}

                                        {/* Only show required/optional selector if not an inventory item */}
                                        {!isInventoryItem && (
                                            <select
                                                className="form-control"
                                                value={isRequired}
                                                onChange={(e) => setIsRequired(e.target.value)}
                                            >
                                                <option value="Optional">Optional</option>
                                                <option value="Required">Required</option>
                                            </select>
                                        )}

                                        <div className="form-check mb-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="inventoryItemCheck"
                                                checked={isInventoryItem}
                                                onChange={(e) => {
                                                    setIsInventoryItem(e.target.checked);
                                                    // Reset field type when switching to inventory item
                                                    if (e.target.checked) {
                                                        setNewFieldType('');
                                                    }
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="inventoryItemCheck">
                                                Inventory Item
                                            </label>
                                        </div>

                                        <button 
                                            className="btn inventory-button inventory-button-primary"
                                            onClick={handleAddField}
                                        >
                                            {isInventoryItem ? 'Add Inventory Item' : 'Add Field'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    )}

                    <main className="col-md-4 mx-2">
                        <div className="field-library-container mt-5">
                            <div className="pt-3">
                                <h2 className="text-center">Selected Fields</h2>
                            </div>

                            <div className="field-library-container">
                                <Droppable droppableId="selectedFields">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="field-section selected-fields"
                                        >
                                            {selectedFields?.filter((field: Field) => field.locked)
                                                .map((field: Field, index: number) => (
                                                    <Draggable
                                                        key={field.id.toString()}
                                                        draggableId={field.id.toString()}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="draggable-field bg-info"
                                                                title={`${field.type}`}
                                                            >
                                                                  <p className="mb-0">{field.content}</p>
                                                                  {isAdmin && (
                                                                      <button
                                                                          className="btn btn-link text-danger p-0 ms-2 delete-btn"
                                                                          onClick={(e) => {
                                                                              e.stopPropagation();
                                                                              handleDeleteConfirmation(Number(field.id));
                                                                          }}
                                                                      >
                                                                          ×
                                                                      </button>
                                                                  )}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>

                            <div className="action-btn">
                                <button
                                    className="btn inventory-button inventory-button-primary"
                                    onClick={handleSave}
                                >
                                    Save Layout
                                </button>
                                <button
                                    className="btn inventory-button inventory-button-secondary preview-btn"
                                    onClick={handlePreview}
                                >
                                    Preview
                                </button>
                            </div>
                        </div>
                    </main>
                </div>
            </DragDropContext>

            <CModal
                visible={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                alignment="center"
                size="sm"
            >
                <CModalHeader closeButton>
                    <h5 className="mb-0">Confirm Delete</h5>
                </CModalHeader>
                <CModalBody>
                    Are you sure you want to delete this field?
                </CModalBody>
                <CModalFooter>
                    <button
                        className="btn inventory-button inventory-button-secondary"
                        onClick={() => setShowDeleteModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn inventory-button inventory-button-danger"
                        onClick={confirmDelete}
                    >
                        Delete
                    </button>
                </CModalFooter>
            </CModal>
        </div>
    );
};

export default InputParametersDnD;
