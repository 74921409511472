import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { getApplications } from '../applications';
import { cookieUtils } from '../../../utils/cookieUtils';

interface PayloadType {
    get: (key: string) => string;
}

interface User {
    id: number;
    name: string;
    email: string;
    isAdmin: boolean;
}

type SetUserType = (user: User | null) => void;
type NavigateType = (path: string) => void;

export const Login = async (payload: PayloadType, setUser: SetUserType, navigate: NavigateType) => {

    let toastId = toast.loading('Logging In...');

    try {
        const body = {
            email: payload.get('username'),
            password: payload.get('password'),
        };

        cookieUtils.set('UserEmail', payload.get('username') || '');
        const apiResponse = await axios.post(`${process.env.REACT_APP_API_URL}/user/login/`, body);
        cookieUtils.set('tokens', JSON.stringify(apiResponse.data));
        if(apiResponse?.data?.company_custom_fields?.length > 0 ){
            await cookieUtils.set('selectedFields', JSON.stringify(apiResponse?.data?.company_custom_fields));
        }
        if (apiResponse?.data?.user_name) {
            console.log('apiResponse?.data?.user_name', apiResponse?.data?.user_name);
            cookieUtils.set('UserName', apiResponse?.data?.user_name);
        } else {
            console.log('payload.get(username)', payload.get('username'));
            cookieUtils.set('UserName', payload.get('username') || '');
        }

        await getApplications();
        setUser(jwt_decode(apiResponse.data.token.access));

        toast.update(toastId, { render: 'Welcome!!', type: 'success', isLoading: false });
        setTimeout(() => {
            toast.dismiss(toastId);
            navigate('/Home');
        }, 1000);

    } catch (error: any) {
        console.log('error=>', error);
        const errorMessage = error?.response?.data?.errors?.message
            ? error.response.data.errors.message[0]
            : error?.response?.data?.message || 'An unexpected error occurred';

        toast.update(toastId, {
            render: errorMessage,
            type: 'error',
            isLoading: false,
        });
        setTimeout(() => {
            toast.dismiss(toastId);
        }, 3000);
    }
};
