import React, {
    useState,
    useEffect,
    FormEvent,
    useCallback,
  } from "react";
  import { CSpinner} from "@coreui/react";
  import Button from "@mui/material/Button";
  import { useNavigate } from "react-router-dom";
  import { toast } from "react-toastify";
  import "../../../../assets/css/common.css";
  import "../../../../assets/css/inputparameter.css";
  import jwtInterceoptor from "../../../../views/shared/jwtInterceptor";
  import useUserProfile from "../../../../hooks/useUserProfile";
  import useCurrentCompanyUsersWithAccessToExtracto from "../../../../hooks/useCurrentCompanyUsersWithAccessToExtracto";
  import { useInputParametersDnD } from "../../../../hooks/useInputParametersDnD";
  import { FaPencilAlt } from "react-icons/fa";
  import { cookieUtils } from "../../../../utils/cookieUtils";
  import CreatableSelect from 'react-select/creatable';
  
  interface Field {
    id: string;
    content: string;
    type: "Text" | "Number" | "Mixed" | "Dropdown" | "Dropdown(Custom)";
    locked: boolean;
    options?: { value: string; label: string }[];
    additional_info?: string[];
  }
  
  interface FormValues {
    input_weight?: number;
    strain_name?: string;
    pressure?: number;
    pressure2?: number;
    temperature?: number;
    temperature2?: number;
    operator?: string;
    batchid?: string;
  }
  
  interface FormErrors {
    input_weight?: string;
    strain_name?: string;
    pressure?: string;
    pressure2?: string;
    temperature?: string;
    temperature2?: string;
    operator?: string;
    batchid?: string;
  }
  interface User {
    id: number;
    first_name?: string;
    last_name?: string;
    email: string;
    name: string;
  }
  
  function InputParameters() {
    const navigate = useNavigate();
    const [, setFields] = useState<Field[]>([]);
    const [selectedFields, setSelectedFields] = useState<Field[]>([]);
  
    const { fetchInputParameters } = useInputParametersDnD({
      setFields,
      setSelectedFields,
      defaultFields: [],
      initialSelectedFields: [],
    });
  
    // Memoize preview mode check
    const isPreviewMode = cookieUtils.get("preview-mode") || false;
    const currentCompanyUsersWithAccessToExtracto = useCurrentCompanyUsersWithAccessToExtracto();
    const [optionsForOperatorSelectField, setOptionsForOperatorSelectField] = useState<{ label: string; value: number }[]>([]);
  
    useEffect(() => {
      if (currentCompanyUsersWithAccessToExtracto) {
        let users = currentCompanyUsersWithAccessToExtracto.map((user: User) => ({
          label: user.name,
          value: user.id,
        }));
        setOptionsForOperatorSelectField(users);
      }
    }, [currentCompanyUsersWithAccessToExtracto]);
  
    // Modify the useEffect that fetches input parameters
    useEffect(() => {
      console.log("isPreviewMode", isPreviewMode);
      if (isPreviewMode) {
        // In preview mode, load fields from cookies
        const previewFields = cookieUtils.get("preview-selected-fields");
        if (previewFields) {
          const batchField = {
            id: "batchId",
            content: "BatchId",
            type: "Mixed" as const,
            locked: true,
          };
          setSelectedFields([batchField, ...previewFields]);
          console.log("previewFields", previewFields);
        }
      } else {
        // Normal mode - fetch from API and add batch field
        fetchInputParameters().then(() => {
          setSelectedFields((prevFields) => {
            const batchField = {
              id: "batchId",
              content: "BatchId",
              type: "Mixed" as const,
              locked: true,
            };
            
            // Add test inventory field for testing
            // const testInventoryField = {
            //   id: "inventory_34",
            //   content: "Test Inventory Item",
            //   type: "Number" as const,
            //   locked: false,
            // };
            
            return [batchField, ...prevFields];
          });
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    // Clean up preview mode when component unmounts
    useEffect(() => {
      return () => {
        cookieUtils.remove("preview-mode");
        cookieUtils.remove("preview-selected-fields");
      };
    }, []);
  
      // Update the getFieldKey function to handle special cases
    const getFieldKey = (content: string) => {
      // First handle special cases
      if (content === "Temperature (°C)") return "temperature";
      if (content === "Temperature 2 (°C)") return "temperature2";
      if (content === "Pressure (psi)") return "pressure";
      if (content === "Pressure 2 (psi)") return "pressure2";
      if (content === "Operator") return "operator";
  
      // For other cases, use the standard transformation
      return content.toLowerCase().replace(/[()°\s]/g, "_");
    };
  
    // Initialize with empty values
    const [values, setValues] = useState<FormValues>({
      operator: cookieUtils.get("UserName") || "",
      batchid: "",
    });
  
    // Update values when selectedFields change
    useEffect(() => {
      if (selectedFields.length > 0) {
        const newValues: FormValues = {
          operator: cookieUtils.get("UserName") || "",
          batchid: "",
        };
  
        selectedFields.forEach(field => {
          const fieldKey = getFieldKey(field.content);
          if (field.type === "Number") {
            (newValues as any)[fieldKey] = 0;
          } else if (field.type === "Text" || field.type === "Mixed" || field.type === "Dropdown(Custom)") {
            (newValues as any)[fieldKey] = "";
          }
        });
  
        setValues(newValues);
      }
    }, [selectedFields]);
  
    const [errors, setErrors] = useState<FormErrors>({});
    const [loader, setLoader] = useState(false);
    const { userProfile } = useUserProfile();
    const userType: any = userProfile?.role;
  
    // Handle the redirection
    const handleRedirect = () => {
      navigate("/InputParametersDnD");
    };
  
    // Optimize handleChange with value comparison
    const handleChange = useCallback((value: any, field: string) => {
      setValues((prevValues) => {
        if (prevValues[field as keyof FormValues] === value) return prevValues;
        return {
          ...prevValues,
          [field]: value,
        };
      });
      setErrors((prevErrors) => {
        if (!prevErrors[field as keyof FormErrors]) return prevErrors;
        return {
          ...prevErrors,
          [field]: "",
        };
      });
    }, []);
  
    const handleCreateOption = async (inputValue: any) => {
      let toastId = toast.loading("Registering operator.");
      const formData = new FormData();
      formData.append("name", inputValue);
      const response = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/add-operator/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 201 || response.status === 200) {
        const responseData = response.data;
        let users = responseData?.users.map((user: User) => ({
          label: user.name,
          value: user.id,
        }));
        setOptionsForOperatorSelectField(users);
  
        toast.update(toastId, {
          render: "Registration Successfully",
          type: "success",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(toastId);
        }, 2000);
      }
    };
  
    // Update the handleSubmit with more careful inventory field handling
    const handleSubmit = useCallback(
      async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
  
        // Only validate that batchid is provided
        if (!values.batchid?.trim()) {
          setErrors({ batchid: "BatchId is required" });
          return;
        }
  
        try {
          setLoader(true);
  
          // Extract batchId directly from values
          console.log("Submitting values:", values);
          const { batchid } = values;
          
          // Prepare payload with dynamic fields and inventory fields
          const dynamicFields: Record<string, any> = {};
          const inventoryFields: Record<string, any> = {};
          
          // Log selected fields for debugging
          console.log("Selected fields:", selectedFields);
          
          // Process each field more carefully
          selectedFields.forEach(field => {
            // Skip the batch ID field
            if (field.id === "batchId") return;
            
            const fieldKey = getFieldKey(field.content);
            const fieldValue = (values as any)[fieldKey];
            
            console.log(`Processing field: ${field.id}, content: ${field.content}, key: ${fieldKey}, value:`, fieldValue);
            
            // Check if this is an inventory field by ID prefix
            if (typeof field.id === 'string' && field.id.startsWith('inventory_')) {
              // For inventory fields, use the field ID to extract the inventory ID number
              const inventoryId = field.id.split('_')[1]; // Get the number after "inventory_"
              
              // Store with the inventory ID as key and the field value
              inventoryFields[inventoryId] = fieldValue;
              console.log(`Added to inventory fields with ID ${inventoryId}:`, fieldValue);
            } else {
              // For regular fields, add to dynamic fields if not operator
              if (field.content !== "Operator") {
                dynamicFields[fieldKey.toLowerCase()] = fieldValue;
                console.log(`Added to dynamic fields with key ${fieldKey}:`, fieldValue);
              }
            }
          });
          
          // Log the prepared data
          console.log("Dynamic fields:", dynamicFields);
          console.log("Inventory fields:", inventoryFields);
          
          // Prepare the final payload
          const payload = {
            batch_name: batchid,
            operator: values.operator || "",
            dynamic_fields: Object.keys(dynamicFields).length > 0 ? dynamicFields : {},
            inventory: Object.keys(inventoryFields).length > 0 ? inventoryFields : undefined
          };
          
          console.log("Final payload:", payload);
  
          const response = await jwtInterceoptor.post(
            `${process.env.REACT_APP_API_URL}/user/api/input-parameters/`,
            payload
          );
  
          // Batch cookies updates
          const storageUpdates = {
            input_parameter: response?.data?.id,
            run: "true",
          };
          Object.entries(storageUpdates).forEach(([key, value]) =>
            cookieUtils.set(key, value)
          );
  
          navigate("/ExtractoPredictionDashboard", {
            state: {
              data: {
                strain: values.strain_name,
                input_weight: values.input_weight,
                operator: values.operator,
                pressure: values.pressure,
                temperature: values.temperature,
                batch_number: values.batchid,
                run: true,
              },
            },
          });
        } catch (err) {
          console.error("Error submitting form:", err);
          toast.error(
            "Error occurred during processing request. Please contact administrator."
          );
        } finally {
          setLoader(false);
        }
      },
      [values, navigate, selectedFields]
    );
  
    return (
      <div className="input-parameters-container">
        <div className="header-section">
          {userType === "Company Admin" && (
            <div className="customize-link">
              <span onClick={handleRedirect} className="edit-fields-link">
                <FaPencilAlt className="edit-icon" />
                Customize Run Sheet Fields
              </span>
            </div>
          )}
        </div>
  
        <div className="main-content">
          <div className="input-header">
            <h2 className="page-title">Enter Post Purge Parameters</h2>
            <p className="page-description">
              Fill in Post Purge Parameters before running the extraction process.
            </p>
          </div>
  
          <form onSubmit={handleSubmit}>
            <div className="fields-grid">
              {selectedFields.map((field: Field) => {
                const fieldKey = getFieldKey(field.content);
                return (
                  <div key={field.id} className="mb-3">
                    <label className="form-label">
                      {field.content}
                      {field.content === "BatchId" && <span style={{ color: "red" }}>*</span>}
                    </label>
                    {field.type === "Dropdown" ? (
                      <CreatableSelect
                        className={errors[fieldKey as keyof FormErrors] ? 'is-invalid' : ''}
                        name={field.id}
                        value={values[fieldKey as keyof FormValues] ?
                          optionsForOperatorSelectField.find(opt => opt.value === values[fieldKey as keyof FormValues]) : null}
                        onChange={(newValue) => handleChange(newValue?.label || "", fieldKey)}
                        options={field.content === "Operator" ? optionsForOperatorSelectField : []}
                        onCreateOption={handleCreateOption}
                        isClearable
                        placeholder={field.content === "Operator" ? "Select your operator..." : "Select or create an option..."}
                      />
                    ) : field.type === "Dropdown(Custom)" ? (
                      <select
                        className={`form-control ${errors[fieldKey as keyof FormErrors] ? 'is-invalid' : ''}`}
                        name={field.id}
                        value={values[fieldKey as keyof FormValues] || ""}
                        onChange={(e) => handleChange(e.target.value, fieldKey)}
                      >
                        <option value="">Select an option</option>
                        {field.additional_info?.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={field.type === "Number" ? "number" : "text"}
                        className={`form-control ${errors[fieldKey as keyof FormErrors] ? 'is-invalid' : ''}`}
                        name={field.id}
                        value={values[fieldKey as keyof FormValues] || ""}
                        onChange={(e) => handleChange(e.target.value, fieldKey)}
                      />
                    )}
                    {errors[fieldKey as keyof FormErrors] && (
                      <div className="error">{errors[fieldKey as keyof FormErrors]}</div>
                    )}
                  </div>
                );
              })}
            </div>
  
            <div className="form-actions">
              {cookieUtils.get("preview-mode") !== true && (
                <Button
                  variant="contained"
                  type="submit"
                  className="submit-button"
                  disabled={loader}
                >
                  {loader ? (
                    <CSpinner color="success" className="spinner" />
                  ) : (
                    "Initiate Run"
                  )}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
  
  export default InputParameters;