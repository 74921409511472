import React, { useState} from 'react';
import { CButton, CFormInput } from '@coreui/react';
import "../../assets/css/simulation.css";

interface ResultsEmailModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (name: string, email: string, company: string) => Promise<void>;
  isSubmitting: boolean;
}

const ResultsEmailModal: React.FC<ResultsEmailModalProps> = ({ 
  show, 
  onClose, 
  onSubmit,
  isSubmitting
}) => {
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  if (!show) return null;

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = re.test(email);
    if (!isValid) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
    return isValid;
  };

  const handleSubmit = async () => {
    if (!email.trim()) {
      setEmailError('Email is required');
      return;
    }

    if (!validateEmail(email)) {
      return;
    }

    try {
      await onSubmit(name, email, company);
    } catch (error) {
      console.error('Error submitting email information:', error);
    }
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div className="results-email-overlay" onClick={onClose}>
      <div className="results-email-modal" onClick={e => e.stopPropagation()}>
        <div className="results-email-modal-header">
          <h2 className="results-email-modal-title">
            <span className="results-email-icon">🚀</span> Your Results Are Ready – Where Should We Send Them?
          </h2>
        </div>
        <div className="results-email-modal-body">
          <div className="results-email-form-group">
            <label htmlFor="email" className="results-email-label">Email</label>
            <div className="results-email-input-wrapper">
              <CFormInput
                type="email"
                id="email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                className={`results-email-input ${emailError ? 'is-invalid' : ''}`}
                placeholder="Enter your email address"
              />
              {emailError && <div className="results-email-error">{emailError}</div>}
            </div>
          </div>
          <div className="results-email-form-group">
            <label htmlFor="name" className="results-email-label">Name</label>
            <div className="results-email-input-wrapper">
              <CFormInput
                type="text"
                id="name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                className="results-email-input"
                placeholder="Enter your name"
              />
            </div>
          </div>
          <div className="results-email-form-group">
            <label htmlFor="company" className="results-email-label">Company</label>
            <div className="results-email-input-wrapper">
              <CFormInput
                type="text"
                id="company"
                value={company}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompany(e.target.value)}
                className="results-email-input"
                placeholder="Enter your company name (optional)"
              />
            </div>
          </div>
        </div>
        <div className="results-email-modal-footer">
          <CButton
            className="results-email-button results-email-button-primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Send My Results'}
          </CButton>
          <CButton
            className="results-email-button results-email-button-secondary"
            onClick={handleClose}
          >
            Not Now
          </CButton>
        </div>
      </div>
    </div>
  );
};

export default ResultsEmailModal; 