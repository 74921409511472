import React, { useState, useContext } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CFormSwitch,
  CFormInput,
  CTooltip,
} from '@coreui/react';
import { FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import jwtInterceoptor from '../../../../../views/shared/jwtInterceptor';
import { toast } from 'react-toastify';
import './styles.css';
import { FieldsContext } from '../../../../../contexts/InputParameters/FieldsProvider';

interface CustomizeExperienceProps {
  onComplete: () => void;
  onSkip: () => void;
}

const CustomizeExperience: React.FC<CustomizeExperienceProps> = ({ onComplete, onSkip }) => {
  const {
    initialFields,
    setSelectedFields,
    selectedFields,
    setFields,
    fields
  } = useContext(FieldsContext);
  const navigate = useNavigate();
  const [isAutomated, setIsAutomated] = useState(false);
  const [automationTime, setAutomationTime] = useState('09:00');
  const [automationDate, setAutomationDate] = useState(new Date().toISOString().split('T')[0]);

  const handleAutomationToggle = (checked: boolean) => {
    setIsAutomated(checked);
    if (!checked) {
      setAutomationTime('09:00');
      setAutomationDate(new Date().toISOString().split('T')[0]);
    }
  };

  const handleTimeChange = (time: string) => {
    setAutomationTime(time);
  };

  const handleDateChange = (date: string) => {
    setAutomationDate(date);
  };

  const saveAutomationSettings = async () => {
    if (isAutomated) {
      try {
        await jwtInterceoptor.post(
          `${process.env.REACT_APP_API_URL}/user/company/automation-settings/`,
          {
            is_automated: true,
            automation_time: automationTime,
            automation_date: automationDate,
          }
        );
        toast.success('Automation settings saved successfully!');
      } catch (error) {
        toast.error('Failed to save automation settings.');
      }
    }
  };

  const handleGetStarted = () => {
    onComplete();
    navigate('/ExtractoPredictionDashboard');
  };

  const handleFieldSelection = (fieldId: string, checked: boolean) => {
    const fieldToToggle = initialFields.find((field: any) => field.id === fieldId);

    if (checked) {
      // Add to selectedFields and remove from fields
      if (!selectedFields.some((field: any) => field.id === fieldId)) {
        setSelectedFields([...selectedFields, fieldToToggle]);
        setFields(fields.filter((field: any) => field.id !== fieldId));
      }
    } else {
      // Remove from selectedFields and add to fields
      const fieldToMove = selectedFields.find((field: any) => field.id === fieldId);
      setSelectedFields(selectedFields.filter((field: any) => field.id !== fieldId));
      if (!fields.some((field: any) => field.id === fieldId)) {
        setFields([...fields, fieldToMove]);
      }
    }
  };

  return (
    <div className="customize-experience">
      <div className="step-header">
        <h2>Customize Your Experience</h2>
        <p className="estimated-time">Estimated Time: 3-5 minutes</p>
      </div>

      <CCard className="settings-card">
        <CCardBody>
          <h3 className="settings-section-title">System Health Checks</h3>
          <CCardText>
            Configure automated system health checks to ensure optimal performance.
          </CCardText>

          <div className="automation-settings">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <CFormSwitch
                  id="automationSwitch"
                  checked={isAutomated}
                  onChange={(e) => handleAutomationToggle(e.target.checked)}
                />
                <label htmlFor="automationSwitch" className="ms-2">
                  Enable Automated Health Checks
                </label>
                <CTooltip content="System will automatically run daily health checks at the specified time" placement="right">
                  <span>
                    <FaInfoCircle className="ms-2 text-muted" style={{ cursor: 'pointer' }} />
                  </span>
                </CTooltip>
              </div>
            </div>

            {isAutomated && (
              <div className="time-selector mb-4">
                <div className="mb-3">
                  <label className="me-2">Select Date</label>
                  <CFormInput
                    type="date"
                    value={automationDate}
                    onChange={(e) => handleDateChange(e.target.value)}
                    className="w-auto"
                  />
                </div>
                <div className="mb-3">
                  <label className="me-2">Daily Check Time</label>
                  <CFormInput
                    type="time"
                    value={automationTime}
                    onChange={(e) => handleTimeChange(e.target.value)}
                    className="w-auto"
                  />
                </div>
                <CButton
                  color="dark"
                  onClick={saveAutomationSettings}
                  className="mt-3"
                >
                  Save Automation Settings
                </CButton>
              </div>
            )}
          </div>

          <h3 className="settings-section-title mt-4">Available Parameters</h3>
          <CCardText>Select the parameters you want to include:</CCardText>

          <div className="parameter-list">
            {initialFields
              .filter((field: any) => !field.locked)
              .map((field: any) => (
                <div key={field.id} className="d-flex align-items-center mb-2">
                  <CFormSwitch
                    id={`field-${field.id}`}
                    checked={selectedFields.some((selected: any) => selected.id === field.id)}
                    onChange={(e) => handleFieldSelection(field.id, e.target.checked)}
                  />
                  <label htmlFor={`field-${field.id}`} className="ms-2">
                    {field.content}
                  </label>
                </div>
              ))}
          </div>
        </CCardBody>
      </CCard>

      <div className="navigation-buttons">
        <CButton
          color="dark"
          variant="ghost"
          onClick={onSkip}
          className="skip-button"
          style={{ backgroundColor: '#f0f0f0' }}
        >
          Skip for now
        </CButton>
        <CButton
          color="dark"
          onClick={handleGetStarted}
          className="get-started-btn"
        >
          Let&apos;s Get Started
        </CButton>
      </div>
    </div>
  );
};

export default CustomizeExperience;
