import axios from 'axios'
import {toast} from 'react-toastify';
import {cookieUtils} from '../../utils/cookieUtils';

const jwtInterceoptor = axios.create({});

jwtInterceoptor?.interceptors.request.use((config) => {
	const tokens = JSON.parse(cookieUtils.get('tokens') || '{}');
	let tokensData = tokens || "";

	config.headers['Authorization'] = `Bearer ${tokensData?.token?.access}`

	return config
})

jwtInterceoptor?.interceptors.response.use(
	(response) => {
		return response
	},
	async (error) => {
		if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
			toast.error("Timeout Please Try Again", {hideProgressBar: true, autoClose: 5000});
		}

		if (error.response?.status === 401) {
			const authData = JSON.parse(cookieUtils.get('tokens') || '{}');
			if (!authData) {
				return Promise.reject(error)
			}
			const payload = {
				access: authData?.token?.access,
				refresh: authData?.token?.refresh,
			}

			// Verify if the token is still valid
			try {
				await axios.post(`${process.env.REACT_APP_API_URL}/user/token/verify/`, {token: authData?.token?.access});
				// If no error was thrown, the token is still valid.
				// The error might be due to something else, so handle accordingly.
			} catch (verificationError: any) {
				// the token is invalid, try to refresh it
				if (verificationError.response.status === 401) {
					//  token refresh logic
					try {
						let apiResponse = await axios.post(
							`${process.env.REACT_APP_API_URL}/user/token/refresh/`,
							payload,
						)
						const tokens = {
							token: {
								access: apiResponse.data.access,
								refresh: authData.token.refresh, // refresh token is old
							},
						}
						cookieUtils.set('tokens', JSON.stringify(tokens))
						error.config.headers['Authorization'] = `Bearer ${apiResponse.data.access}`
						return axios(error.config)
					} catch (error) {
						toast.error("Authentication Expired !! Please Login again", {
							hideProgressBar: true,
							autoClose: false
						});
						console.log(error)
						cookieUtils.remove('tokens')
						window.location.href = '/'
					}
				}
			}
		} else {
			if (error.response?.status === 403) {
				toast.error("Access Forbidden! Please Login again", {
					hideProgressBar: true,
					autoClose: false
				});
				cookieUtils.remove('tokens');
				window.location.href = '/';
			}
			if (error?.response?.data?.message) {
				let combinedError = 'Error!';
				if (Array.isArray(error?.response?.data?.message)) {
					combinedError = (error?.response?.data?.message).join('\n');
				} else {
					combinedError = error?.response?.data?.message;
				}
        console.log(combinedError)
				// toast.error(`${combinedError}`, {hideProgressBar: true, autoClose: 5000});
			}
			return Promise.reject(error)
		}
	},
)

export default jwtInterceoptor;
