import React, { useState, useEffect } from 'react';
import { 
  CTable, CTableBody, CTableDataCell, 
  CTableHead, CTableHeaderCell, CTableRow, CFormInput,
  CButton, CTooltip
} from '@coreui/react';
import { FaTrash, FaEdit, FaPlus, FaSearch } from 'react-icons/fa';
import '../../assets/css/inventory.css';
import InventoryModal from '../../components/Manager_Dashboard/InventoryModal';
import DeleteConfirmationModal from '../../components/Manager_Dashboard/DeleteConfirmationModal';
import InventoryUsageModal from '../../components/Manager_Dashboard/InventoryUsageModal';
import jwtInterceptor from '../../views/shared/jwtInterceptor';
import { toast } from 'react-toastify';

interface InventoryItem {
  id: string;
  name: string;
  currentInventory: number;
  threshold: number;
  email: string;
  unit: string;
  costPerGram?: number;
  notificationsEnabled: boolean;
}

// API response interface to match the backend structure
interface InventoryApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<{
    id: number;
    name: string;
    current_inventory: number;
    threshold: number;
    notification_email: string;
    cost_per_gram: string;
    unit: string;
    notifications_enabled: boolean;
    is_active: boolean | null;
    created_at: string;
    updated_at: string;
    company: number;
  }>;
}

const Inventory = () => {
  const [items, setItems] = React.useState<InventoryItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [selectedItem, setSelectedItem] = React.useState<Partial<InventoryItem>>({});
  const [showModal, setShowModal] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<InventoryItem[]>(items);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<InventoryItem | null>(null);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [currentItemForUsage, setCurrentItemForUsage] = useState<InventoryItem | null>(null);

  // Fetch inventory data from API
  const fetchInventory = async () => {
    try {
      setLoading(true);
      const response = await jwtInterceptor.get(`${process.env.REACT_APP_API_URL}/user/api/inventory/`);
      
      const data: InventoryApiResponse = response.data;
      
      // Transform API data to match our component's data structure
      const transformedItems: InventoryItem[] = data.results.map(item => ({
        id: item.id.toString(),
        name: item.name,
        currentInventory: item.current_inventory,
        threshold: item.threshold,
        email: item.notification_email,
        unit: item.unit,
        costPerGram: parseFloat(item.cost_per_gram),
        notificationsEnabled: item.notifications_enabled
      }));
      
      setItems(transformedItems);
      setError(null);
    } catch (err) {
      console.error('Error fetching inventory:', err);
      setError('Failed to load inventory data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredItems(items);
      return;
    }

    const filtered = items.filter((item) => {
      const searchStr = searchTerm.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchStr) ||
        item.email.toLowerCase().includes(searchStr) ||
        item.currentInventory.toString().includes(searchStr)
      );
    });
    setFilteredItems(filtered);
  }, [searchTerm, items]);

  const handleAddItem = async () => {
    // Check if any required field is undefined, null, or empty string
    if (!selectedItem.name || 
        selectedItem.currentInventory === undefined || 
        selectedItem.currentInventory === null || 
        selectedItem.threshold === undefined || 
        selectedItem.threshold === null || 
        !selectedItem.email || 
        !selectedItem.unit) {
      console.log("Missing fields:", selectedItem); // For debugging
      toast.error('Please fill in all fields');
      return;
    }

    try {
      // Prepare the request body according to API requirements
      const requestBody = {
        name: selectedItem.name,
        current_inventory: Number(selectedItem.currentInventory),
        threshold: Number(selectedItem.threshold),
        notification_email: selectedItem.email,
        unit: selectedItem.unit,
        cost_per_gram: selectedItem.costPerGram || 0,
        notifications_enabled: true
      };

      // Make the API call
      const response = await jwtInterceptor.post(
        `${process.env.REACT_APP_API_URL}/user/api/inventory/`, 
        requestBody
      );

      if (response.status === 201 || response.status === 200) {
        // Transform the API response to match our component's data structure
        // const temp = {
        //     "id": 1,
        //     "name": "CRC 2",
        //     "current_inventory": 10000,
        //     "threshold": 500,
        //     "notification_email": "test@example.com",
        //     "cost_per_gram": "0.15",
        //     "unit": "g",
        //     "notifications_enabled": true,
        //     "is_active": true,
        //     "created_at": "2024-01-23T...",
        //     "updated_at": "2024-01-23T...",
        //     "company": 2
        // }
        const newItem: InventoryItem = {
          id: response.data.id.toString(),
          name: response.data.name,
          currentInventory: response.data.current_inventory,
          threshold: response.data.threshold,
          email: response.data.notification_email,
          unit: response.data.unit,
          costPerGram: parseFloat(response.data.cost_per_gram),
          notificationsEnabled: response.data.notifications_enabled
        };

        // Update the state with the new item
        setItems([...items, newItem]);
        setShowModal(false);
        setSelectedItem({});
        
        // Show success message
        toast.success('Item added successfully');
      }
    } catch (error) {
      console.error('Error adding inventory item:', error);
      toast.error('Failed to add inventory item. Please try again.');
    }
  };

  const handleEditItem = async () => {
    if (!selectedItem.id) return;
    
    try {
      // Prepare the request body according to API requirements
      const requestBody = {
        name: selectedItem.name,
        current_inventory: Number(selectedItem.currentInventory),
        threshold: Number(selectedItem.threshold),
        notification_email: selectedItem.email,
        unit: selectedItem.unit,
        cost_per_gram: selectedItem.costPerGram || 0,
        notifications_enabled: selectedItem.notificationsEnabled !== undefined 
          ? selectedItem.notificationsEnabled 
          : true
      };

      // Make the API call
      const response = await jwtInterceptor.put(
        `${process.env.REACT_APP_API_URL}/user/api/inventory/${selectedItem.id}/`, 
        requestBody
      );

      if (response.status === 200) {        
        // Show success message
        toast.success('Item updated successfully');
        fetchInventory();
      }
    } catch (error) {
      console.error('Error updating inventory item:', error);
      toast.error('Failed to update inventory item. Please try again.');
    } finally {
      setShowModal(false);
      setSelectedItem({});
      setIsEditing(false);
    }
  };

  const getInventoryStatus = (currentInventory: number, threshold: number) => {
    const ratio = currentInventory / threshold;
    if (ratio > 2) return { status: 'success', message: 'Good Stock Level' };
    if (ratio > 1) return { status: 'warning', message: 'Stock Running Low' };
    return { status: 'danger', message: 'Critical Stock Level' };
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'success':
        return { icon: '✅', color: '#28a745' };
      case 'warning':
        return { icon: '⚠️', color: '#ffc107' };
      case 'danger':
        return { icon: '❌', color: '#dc3545' };
      default:
        return { icon: '❓', color: '#6c757d' };
    }
  };

  const handleDeleteItem = async () => {
    if (!itemToDelete) return;
    
    try {
      // Make the API call to delete the item
      const response = await jwtInterceptor.delete(
        `${process.env.REACT_APP_API_URL}/user/api/inventory/${itemToDelete.id}/`
      );
      
      if (response.status === 204 || response.status === 200) {
        // Remove the item from the local state
        setItems(items.filter(i => i.id !== itemToDelete.id));
        
        // Show success message
        toast.success('Item deleted successfully');
        fetchInventory();
      }
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      toast.error('Failed to delete inventory item. Please try again.');
    } finally {
      // Close the modal and reset the itemToDelete state
      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };

  const handleUpdateInventoryWithDetails = async (amount: number, notes: string, cost: number) => {
    if (!currentItemForUsage) return;
    
    try {
      setLoading(true);
      
      // If amount is negative, it's a usage record
      // if (amount < 0) {
        // For usage recording, we need to send a positive quantity
        const requestBody = {
          quantity: amount,
          notes: notes
        };

        const inventoryId = currentItemForUsage.id;

        const response = await jwtInterceptor.post(
          `${process.env.REACT_APP_API_URL}/user/api/inventory/${inventoryId}/record_usage/`,
          requestBody
        );
        
        // STATIC RESPONSE - Replace API call with mock data
        // Simulate API response
        // const mockResponse = {
        //   current_inventory: currentItemForUsage.currentInventory + amount, // Subtract the amount
        //   transaction_id: 1,
        //   notes: notes
        // };
        
        // Update the local state with the new inventory level from the mock response
        setItems(items.map(item => 
          item.id === currentItemForUsage.id 
            ? { 
                ...item, 
                currentInventory: response.data.current_inventory
              }
            : item
        ));
        
        // Show success message
        toast.success(response.data.message);
    } catch (error: any) {
      console.error('Error updating inventory:', error);
      toast.error(error.response?.data?.error || 'Failed to update inventory. Please try again.');
    } finally {
      setLoading(false);
      setShowUsageModal(false);
      setCurrentItemForUsage(null);
    }
  };

  return (
    <div className="container mt-4">
      <h1 className="page-title mt-0 me-4 mt-2">Inventory Management</h1>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="search-container position-relative">
          <FaSearch className="position-absolute top-50 translate-middle-y ms-3 text-muted" />
          <input
            type="text"
            className="form-control search-input ps-5"
            placeholder="Search Inventory..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <CButton 
          color="primary"
          className="btn-custom btn-export"
          onClick={() => {
            setIsEditing(false);
            setSelectedItem({});
            setShowModal(true);
          }}
        >
          <FaPlus /> New Item
        </CButton>
      </div>

      <div className="table-container">
        {loading ? (
          <div className="text-center my-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className="mt-2">Loading inventory data...</p>
          </div>
        ) : error ? (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        ) : (
          <CTable hover responsive className="table-custom">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>Status</CTableHeaderCell>
                <CTableHeaderCell>Item Name</CTableHeaderCell>
                <CTableHeaderCell>Current Inventory</CTableHeaderCell>
                <CTableHeaderCell>Stock Threshold (g)</CTableHeaderCell>
                <CTableHeaderCell>Notification Email</CTableHeaderCell>
                <CTableHeaderCell>Cost per Gram</CTableHeaderCell>
                <CTableHeaderCell>Actions</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {filteredItems.map(item => (
                <CTableRow key={item.id}>
                  <CTableDataCell>
                    <CTooltip 
                      content={
                        <div className="inventory-tooltip">
                          <div className="tooltip-title">
                            {getInventoryStatus(item.currentInventory, item.threshold).message}
                          </div>
                          <div className="tooltip-details">
                            Current: {item.currentInventory}{item.unit}<br/>
                            Threshold: {item.threshold}{item.unit}<br/>
                            Status: {((item.currentInventory / item.threshold) * 100).toFixed(0)}% of threshold
                          </div>
                        </div>
                      }
                      placement="left"
                    >
                      <div className="status-indicator-wrapper">
                        <div className="status-indicator">
                          {getStatusIcon(getInventoryStatus(item.currentInventory, item.threshold).status).icon}
                        </div>
                        <div 
                          className="status-progress-bar"
                          style={{
                            '--progress': `${Math.min((item.currentInventory / (item.threshold * 2)) * 100, 100)}%`
                          } as React.CSSProperties}
                        ></div>
                      </div>
                    </CTooltip>
                  </CTableDataCell>
                  <CTableDataCell>{item.name}</CTableDataCell>
                  <CTableDataCell>{item.currentInventory}{item.unit}</CTableDataCell>
                  <CTableDataCell>{item.threshold}{item.unit}</CTableDataCell>
                  <CTableDataCell>{item.email}</CTableDataCell>
                  <CTableDataCell>
                    <CFormInput
                      type="number"
                      value={item.costPerGram || ''}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        setItems(items.map(i => 
                          i.id === item.id 
                            ? { ...i, costPerGram: value }
                            : i
                        ));
                      }}
                      placeholder="Enter cost"
                    />
                  </CTableDataCell>
                  <CTableDataCell>
                    <div className="inventory-action-buttons">
                      <button 
                        className="custom-btn btn-record-usage"
                        onClick={() => {
                          setCurrentItemForUsage(item);
                          setShowUsageModal(true);
                        }}
                      >
                        Record Usage
                      </button>
                      <button 
                        className="custom-btn btn-edit"
                        onClick={() => {
                          setIsEditing(true);
                          setSelectedItem(item);
                          setShowModal(true);
                        }}
                      >
                        <FaEdit />
                      </button>
                      <button 
                        className="custom-btn btn-delete"
                        onClick={() => {
                          setItemToDelete(item);
                          setShowDeleteModal(true);
                        }}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        )}
      </div>

      <InventoryModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedItem({});
        }}
        data={selectedItem}
        onSave={isEditing ? handleEditItem : handleAddItem}
        isEditing={isEditing}
        setData={setSelectedItem}
      />

      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteItem}
        itemName={itemToDelete?.name || ''}
      />

      <InventoryUsageModal
        show={showUsageModal}
        onHide={() => setShowUsageModal(false)}
        item={currentItemForUsage}
        onSave={handleUpdateInventoryWithDetails}
      />
    </div>
  );
};

export default Inventory;
