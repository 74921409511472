import { cookieUtils } from "../../../utils/cookieUtils";

type SetUserType = (user: null) => void;
type NavigateType = (path: string) => void;

export const Logout = (setUser: SetUserType, navigate: NavigateType) => {
    cookieUtils.clear();
    setUser(null);
    navigate('/');
};
