import React from "react";

interface ScanResultsProps {
	show: boolean;
	onClose: () => void;
	title: string;
	question: string;
	yesOption: string;
	noOption: string | null;
	onYes: () => void;
	onNo?: () => void;
	centerYesButton?: boolean;
}

const ScanResults: React.FC<ScanResultsProps> = ({
	show,
	onClose,
	title,
	question,
	yesOption,
	noOption,
	onYes,
	onNo,
	centerYesButton = false
}) => {

	if (!show) return null;

	return (
		<div className="scan-overlay" onClick={onClose}>
			<div className="scan-modal" onClick={e => e.stopPropagation()}>
				<div className="scan-modal-header">
					<h2 className="scan-modal-title">{title}</h2>
				</div>

				<div className="scan-modal-body">
					<h3 className="scan-question">
						{question}
					</h3>
				</div>

				<div className="scan-modal-footer" style={{ justifyContent: centerYesButton ? 'center' : 'flex-end' }}>
					{noOption && (
						<button
							className="scan-button scan-button-secondary"
							onClick={() => {
								onNo?.();
								onClose();
							}}
						>
							{noOption}
						</button>
					)}
					<button
						className="scan-button scan-button-primary"
						onClick={() => {
							onYes();
							onClose();
						}}
					>
						{yesOption}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ScanResults;
